import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Stack,
  Alert,
  Snackbar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  Card
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material';
import { auth, db } from '../../../firebase/config';
import { doc, onSnapshot, updateDoc, collection, getDocs, deleteDoc, query, where } from 'firebase/firestore';
import {
  subscribeToMetaData,
  addCity,
  updateCity,
  deleteCity,
  subscribeToUserByEmail,
  createUser,
  fetchUsers,
  updateUser,
  deleteUser
} from '../../../Services/FirebaseUtils';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { addDoc } from 'firebase/firestore';
import theme from '../../../theme';

const ROLE_TYPES = [
  'admin',
  'sales-manager',
  'sales-user',
  'operations-user',
  'operations-manager',
  'broker'
];

const Settings = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [userId, setUserId] = useState(null);
  const [metaData, setMetaData] = useState({ cities: [], micromarkets: [] });
  const [editingCity, setEditingCity] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMicromarketsDialogOpen, setIsMicromarketsDialogOpen] = useState(false);
  const [cityForm, setCityForm] = useState({
    name: '',
    state: '',
    micromarkets: []
  });
  const [newMicromarket, setNewMicromarket] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserEditDialogOpen, setIsUserEditDialogOpen] = useState(false);
  const [isUserDeleteDialogOpen, setIsUserDeleteDialogOpen] = useState(false);
  const [newUserForm, setNewUserForm] = useState({
    name: '',
    email: '',
    role: '',
    password: '' // Default password
  });
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);

  // Setup real-time listener for meta data
  useEffect(() => {
    const unsubscribe = subscribeToMetaData(
      (data) => setMetaData(data),
      (error) => {
        console.error("Error listening to meta data:", error);
        setSnackbar({
          open: true,
          message: 'Error listening to updates',
          severity: 'error'
        });
      }
    );

    return () => unsubscribe();
  }, []);

  // Setup real-time listener for user data based on auth email
  useEffect(() => {
    if (!auth.currentUser?.email) {
      console.log("No authenticated user email found");
      return;
    }

    console.log("Looking for user with email:", auth.currentUser.email);

    const unsubscribe = subscribeToUserByEmail(
      auth.currentUser.email,
      (userData) => {
        console.log("Found user data:", userData);
        setUserId(userData.id);
        setUserDetails({
          name: userData.name || "",
          email: userData.email || "",
          role: userData.role || ""
        });
      },
      (error) => {
        console.error("Error fetching user:", error);
        setSnackbar({
          open: true,
          message: 'Error fetching user data',
          severity: 'error'
        });
      }
    );

    return () => unsubscribe();
  }, [auth.currentUser?.email]);

  // Subscribe to specific user document changes
  useEffect(() => {
    if (!userId) return;

    console.log("Subscribing to user document:", userId);
    const userRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(userRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          console.log("User document updated:", userData);
          setUserDetails({
            name: userData.name || "",
            email: userData.email || "",
            role: userData.role || ""
          });
        }
      },
      (error) => {
        console.error("Error watching user document:", error);
        setSnackbar({
          open: true,
          message: 'Error watching user data',
          severity: 'error'
        });
      }
    );

    return () => unsubscribe();
  }, [userId]);

  console.log("Current userDetails:", userDetails);

  const getMicromarketsForCity = (cityName) => {
    return metaData.micromarkets.filter(m => m.city === cityName);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleUserDetailsChange = (field) => (event) => {
    setUserDetails({
      ...userDetails,
      [field]: event.target.value,
    });
  };


  const handleMenuOpen = (event, city) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedCity(city);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEditCity = () => {
    setEditingCity(selectedCity);
    setCityForm({
      name: selectedCity,
      state: '',
      micromarkets: getMicromarketsForCity(selectedCity).map(m => m.micromarket)
    });
    setIsDialogOpen(true);
    handleMenuClose();
  };

  const handleEditMicromarkets = () => {
    setEditingCity(selectedCity);
    setCityForm({
      name: selectedCity,
      state: '',
      micromarkets: getMicromarketsForCity(selectedCity).map(m => m.micromarket)
    });
    setIsMicromarketsDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleAddCity = () => {
    setEditingCity(null);
    setCityForm({
      name: '',
      state: '',
      micromarkets: []
    });
    setIsDialogOpen(true);
  };

  const handleCityFormChange = (field) => (event) => {
    setCityForm({
      ...cityForm,
      [field]: event.target.value
    });
  };

  const handleAddMicromarket = () => {
    if (newMicromarket.trim()) {
      setCityForm(prev => ({
        ...prev,
        micromarkets: [...prev.micromarkets, newMicromarket.trim()]
      }));
      setNewMicromarket('');
    }
  };

  const handleRemoveMicromarket = (micromarket) => {
    setCityForm(prev => ({
      ...prev,
      micromarkets: prev.micromarkets.filter(m => m !== micromarket)
    }));
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteCity(selectedCity);
      setSnackbar({
        open: true,
        message: 'City deleted successfully',
        severity: 'success'
      });
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Error deleting city:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting city',
        severity: 'error'
      });
    }
  };

  const handleSaveCity = async () => {
    try {
      if (editingCity) {
        await updateCity(editingCity, cityForm.name, cityForm.micromarkets);
      } else {
        await addCity(cityForm.name, cityForm.micromarkets);
      }

      setSnackbar({
        open: true,
        message: `City ${editingCity ? 'updated' : 'added'} successfully`,
        severity: 'success'
      });
      setIsDialogOpen(false);
      setIsMicromarketsDialogOpen(false);
    } catch (error) {
      console.error('Error saving city:', error);
      setSnackbar({
        open: true,
        message: `Error ${editingCity ? 'updating' : 'adding'} city`,
        severity: 'error'
      });
    }
  };

  // Fetch users when component mounts
  const loadUsers = async () => {
    try {
      const result = await fetchUsers();
      if (result.success) {
        setUsers(result.users);
      } else {
        setSnackbar({
          open: true,
          message: result.error,
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load users',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  // Edit user handler
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsUserEditDialogOpen(true);
  };

  // Update user handler
  const handleUpdateUser = async () => {
    try {
      if (!selectedUser || !selectedUser.id) {
        setSnackbar({
          open: true,
          message: 'No user selected',
          severity: 'error'
        });
        return;
      }

      const result = await updateUser(selectedUser.id, {
        name: selectedUser.name,
        email: selectedUser.email,
        role: selectedUser.role
      });

      if (result.success) {
        // Close edit dialog
        setIsUserEditDialogOpen(false);
        
        // Reload users table
        await loadUsers();

        // Show success snackbar
        setSnackbar({
          open: true,
          message: 'User updated successfully',
          severity: 'success'
        });
      } else {
        // Show error snackbar
        setSnackbar({
          open: true,
          message: result.error,
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbar({
        open: true,
        message: 'Error updating user',
        severity: 'error'
      });
    }
  };

  // Delete user handler
  const handleDeleteUser = async (userId) => {
    try {
      const result = await deleteUser(userId);

      if (result.success) {
        // Reload users table
        await loadUsers();
        
        // Show success snackbar
        setSnackbar({
          open: true,
          message: 'User deleted successfully',
          severity: 'success'
        });
      } else {
        // Show error snackbar
        setSnackbar({
          open: true,
          message: result.error,
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting user',
        severity: 'error'
      });
    }
  };

  const handleAddUser = async () => {
    try {
      // Validate inputs
      if (!newUserForm.name || !newUserForm.email || !newUserForm.role || !newUserForm.password) {
        setSnackbar({
          open: true,
          message: 'All fields are required',
          severity: 'error'
        });
        return;
      }

      // Call createUser from FirebaseUtils
      const result = await createUser(newUserForm);

      if (result.success) {
        // Reset form and close dialog
        setNewUserForm({
          name: '',
          email: '',
          role: '',
          password: ''
        });
        setIsAddUserDialogOpen(false);

        // Reload users table
        await loadUsers();

        // Show success snackbar
        setSnackbar({
          open: true,
          message: 'User added successfully',
          severity: 'success'
        });
      } else {
        // Show error snackbar
        setSnackbar({
          open: true,
          message: result.error,
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error adding user:', error);
      setSnackbar({
        open: true,
        message: 'Error adding user',
        severity: 'error'
      });
    }
  };

  // Render users table
  const renderUsersTable = () => (
    <Box sx={{ p: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6">Users</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsAddUserDialogOpen(true)}
        >
          Add User
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Chip
                    label={user.role.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    color={
                      user.role === 'admin' ? 'error' :
                        user.role.includes('manager') ? 'primary' :
                          user.role.includes('user') ? 'secondary' :
                            'default'
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditUser(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add User Dialog */}
      <Dialog
        open={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              label="Name"
              fullWidth
              value={newUserForm.name}
              onChange={(e) => setNewUserForm(prev => ({ ...prev, name: e.target.value }))}
            />
            <TextField
              label="Email"
              fullWidth
              type="email"
              value={newUserForm.email}
              onChange={(e) => setNewUserForm(prev => ({ ...prev, email: e.target.value }))}
            />
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={newUserForm.role}
                label="Role"
                onChange={(e) => setNewUserForm(prev => ({ ...prev, role: e.target.value }))}
              >
                {ROLE_TYPES.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Default Password"
              fullWidth
              type="text"
              value={newUserForm.password}
              onChange={(e) => setNewUserForm(prev => ({ ...prev, password: e.target.value }))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setIsAddUserDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  const renderUserManagementTab = () => (
    <Box sx={{ p: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6">User Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsAddUserDialogOpen(true)}
        >
          Add User
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Chip
                    label={user.role.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    color={
                      user.role === 'admin' ? 'error' :
                        user.role.includes('manager') ? 'primary' :
                          user.role.includes('user') ? 'secondary' :
                            'default'
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => {
                    setSelectedUser(user);
                    setIsUserEditDialogOpen(true);
                  }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    setSelectedUser(user);
                    setIsUserDeleteDialogOpen(true);
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add User Dialog */}
      <Dialog
        open={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              label="Name"
              fullWidth
              value={newUserForm.name}
              onChange={(e) => setNewUserForm(prev => ({ ...prev, name: e.target.value }))}
            />
            <TextField
              label="Email"
              fullWidth
              type="email"
              value={newUserForm.email}
              onChange={(e) => setNewUserForm(prev => ({ ...prev, email: e.target.value }))}
            />
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={newUserForm.role}
                label="Role"
                onChange={(e) => setNewUserForm(prev => ({ ...prev, role: e.target.value }))}
              >
                {ROLE_TYPES.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Default Password"
              fullWidth
              type="text"
              value={newUserForm.password}
              onChange={(e) => setNewUserForm(prev => ({ ...prev, password: e.target.value }))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddUserDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  // Update tab rendering to include users tab
  const renderTabContent = () => {
    switch (currentTab) {
      case 0:
        return renderUsersTable();
      case 1:
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Cities</Typography>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddCity}
              >
                Add City
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ height: '100%', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>City Name</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>State</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Micromarkets</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metaData.cities.map((city) => (
                    <TableRow key={city} hover>
                      <TableCell>{city}</TableCell>
                      <TableCell>{city.state || '-'}</TableCell>
                      <TableCell>
                        {getMicromarketsForCity(city).length || 0} micromarkets
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(e) => handleMenuOpen(e, city)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      default:
        return null;
    }
  };

  // Add users tab to existing tabs
  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="settings tabs">
            <Tab label="Users" />
            <Tab label="Cities" />
          </Tabs>
        </Box>
        {renderTabContent()}

        {/* Action Menu */}
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditCity}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit City</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEditMicromarkets}>
            <ListItemIcon>
              <LocationIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Micromarkets</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDeleteClick}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
          </MenuItem>
        </Menu>

        {/* City Edit Dialog */}
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {editingCity ? 'Edit City' : 'Add New City'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label="City Name"
                value={cityForm.name}
                onChange={handleCityFormChange('name')}
              />
              <TextField
                fullWidth
                label="State"
                value={cityForm.state}
                onChange={handleCityFormChange('state')}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setIsDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveCity}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Micromarkets Edit Dialog */}
        <Dialog
          open={isMicromarketsDialogOpen}
          onClose={() => setIsMicromarketsDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Edit Micromarkets for {editingCity}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>Micromarkets</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mb: 2 }}>
                {cityForm.micromarkets.map((micromarket, index) => (
                  <Chip
                    key={index}
                    label={micromarket}
                    onDelete={() => handleRemoveMicromarket(micromarket)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  label="Add Micromarket"
                  value={newMicromarket}
                  onChange={(e) => setNewMicromarket(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddMicromarket();
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={handleAddMicromarket}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setIsMicromarketsDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveCity}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>Delete City</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete {selectedCity}? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button
              onClick={handleDeleteConfirm}
              color="error"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* User Edit Dialog */}
        <Dialog
          open={isUserEditDialogOpen}
          onClose={() => setIsUserEditDialogOpen(false)}
        >
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Name"
              fullWidth
              value={selectedUser?.name || ''}
              onChange={(e) => setSelectedUser(prev => ({ ...prev, name: e.target.value }))}
            />
            <TextField
              margin="dense"
              label="Email"
              fullWidth
              value={selectedUser?.email || ''}
              onChange={(e) => setSelectedUser(prev => ({ ...prev, email: e.target.value }))}
            />
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={selectedUser?.role || ''}
                label="Role"
                onChange={(e) => setSelectedUser(prev => ({ ...prev, role: e.target.value }))}
              >
                {ROLE_TYPES.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setIsUserEditDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary" onClick={handleUpdateUser} >Update</Button>
          </DialogActions>
        </Dialog>

        {/* User Delete Confirmation Dialog */}
        <Dialog
          open={isUserDeleteDialogOpen}
          onClose={() => setIsUserDeleteDialogOpen(false)}
        >
          <DialogTitle>Delete User</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete the user {selectedUser?.name}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsUserDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={() => handleDeleteUser(selectedUser.id)} color="error">Delete</Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Card>

  );
};

export default Settings;
