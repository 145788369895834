import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Chip,
  CardActionArea,
  Divider,
  Breadcrumbs,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
  useMediaQuery,
  ImageList,
  ImageListItem,
  Paper,
  Modal
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChairIcon from '@mui/icons-material/Chair';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';



const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const CenterInventories = () => {
  const { operatorId, centerId } = useParams();
  const [inventories, setInventories] = useState([]);
  const [centerData, setCenterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedInventory(null);
  };

  useEffect(() => {
    const fetchInventories = async () => {
      try {
        setLoading(true);
        const getCenterInventories = httpsCallable(functions, 'getCenterInventories');
        const result = await getCenterInventories({ operatorId, centerId });
        const data = result.data; // Firebase Functions return data in result.data

        console.log('Data:', data);

        if (data.success) {
          setInventories(data.inventories);
          setCenterData(data.center);
        } else {
          setError('Failed to load inventories');
        }
      } catch (err) {
        console.error('Error fetching inventories:', err);
        setError('Failed to load inventories');
      } finally {
        setLoading(false);
      }
    };

    fetchInventories();
  }, [operatorId, centerId]);

  const handleInventoryClick = (inventory) => {
    setSelectedInventory(inventory);
    navigate(`/public/operator/${operatorId}/${centerId}/${inventory.id}`, {
      replace: true,
      state: {
        inventory: {
          ...inventory,
          location: {
            latitude: centerData?.latitude || 28.5355,
            longitude: centerData?.longitude || 77.3910
          }
        },
        centerData: {
          ...centerData,
          amenities: centerData?.amenities || []
        }
      }
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ mb: 4 }}
      >
        <Link color="inherit" href={`/public/operator/${operatorId}`}>
          Centers
        </Link>
        <Typography color="text.primary">{centerData?.centerName}</Typography>
      </Breadcrumbs>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
          {centerData?.centerName}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <LocationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="subtitle1" color="text.secondary">
            {centerData?.locality}, {centerData?.city}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {inventories.map((inventory) => (
          <Grid item xs={12} sm={6} md={4} key={inventory.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              <CardActionArea onClick={() => handleInventoryClick(inventory)}>
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    component="img"
                    height="220"
                    image={inventory.photos?.[0]?.url || 'N/A'}
                    alt={inventory.inventoryName}
                    sx={{ objectFit: 'cover' }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      display: 'flex',
                      gap: 1
                    }}
                  >
                   
                  </Box>
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    {inventory.inventoryName}
                  </Typography>


                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {inventory.amenities?.slice(0, 3).map((amenity, index) => (
                      <Chip
                        key={index}
                        label={amenity}
                        size="small"
                        sx={{
                          backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        }}
                      />
                    ))}
                    {inventory.amenities?.length > 3 && (
                      <Chip
                        label={`+${inventory.amenities.length - 3} more`}
                        size="small"
                        sx={{
                          backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        }}
                      />
                    )}
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>

  );
};

export default CenterInventories;
