import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
  where,
  serverTimestamp,
  arrayRemove,
  deleteField,
  arrayUnion,
  Timestamp,
  limit
} from 'firebase/firestore';
import { db, storage } from '../firebase/config';
import { deleteObject, ref } from 'firebase/storage';
import moment from 'moment';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';
import { 
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth';

const convertTimestampsToDates = (obj) => {
  if (!obj) return obj;

  const converted = { ...obj };
  Object.keys(converted).forEach(key => {
    const value = converted[key];

    // Check if value is a Firestore Timestamp
    if (value && typeof value.toDate === 'function') {
      converted[key] = value.toDate().toISOString();
    }
    // If value is an object (but not a Timestamp), recursively convert its timestamps
    else if (value && typeof value === 'object' && !value.toDate) {
      converted[key] = convertTimestampsToDates(value);
    }
  });

  return converted;
};

export const saveClientName = async (proposalId, editedName) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      clientName: editedName
    });
    return { success: true };
  } catch (error) {
    console.error('Error saving client name:', error);
    return {
      success: false,
      error: error.message || 'Error saving client name'
    };
  }
};

export const saveRequirements = async (proposalId, editedRequirements) => {
  try {
    // Filter out empty requirements
    const filteredRequirements = editedRequirements.filter(req => req.trim() !== '');
    const requirementsString = filteredRequirements.join('\n');

    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      requirements: requirementsString
    });

    return {
      success: true,
      requirements: requirementsString
    };
  } catch (error) {
    console.error('Error saving requirements:', error);
    return {
      success: false,
      error: error.message || 'Error saving requirements'
    };
  }
};

export const saveCenter = async (proposalId, updatedCenter, proposedOptions, selectedIndex) => {
  try {
    // Create a copy of the proposedOptions array
    const updatedOptions = [...proposedOptions];

    // Merge the updated center data with the existing data
    // This ensures we don't lose any properties that weren't in the edit form
    updatedOptions[selectedIndex] = {
      ...proposedOptions[selectedIndex],  // Keep all existing properties
      ...updatedCenter,  // Override with edited properties
    };

    // Update the document in Firestore
    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      proposedOptions: updatedOptions
    });

    return {
      success: true,
      updatedOptions
    };
  } catch (error) {
    console.error('Error updating center:', error);
    return {
      success: false,
      error: error.message || 'Error updating center'
    };
  }
};

export const deleteCenterinProposel = async (ProposelId, centertoDelete, proposedOptions) => {
  try {

    const updatedOptions = proposedOptions.filter(center =>
      center.id !== centertoDelete.id ||
      center.operatorName !== centertoDelete.operatorName
    );

    const deleteRef = doc(db, 'proposals', ProposelId);
    await updateDoc(deleteRef, {
      proposedOptions: updatedOptions
    });

    return {
      success: true,
      updatedOptions,
      message: 'Center deleted successfully'
    };
  } catch (error) {
    console.error('Error deleting operator:', error);
    return {
      success: false,
      error: error.message || 'Error deleting operator'
    };
  }
};

export const deleteCenter = async (centerId) => {
  try {
    const centerRef = doc(db, 'centers', centerId);
    await deleteDoc(centerRef);

    return {
      success: true,
      message: 'Center deleted successfully'
    };
  } catch (error) {
    console.error('Error deleting operator:', error);
    return {
      success: false,
      error: error.message || 'Error deleting operator'
    };
  }
};
export const addToProposal = async (proposalId, selectedOption) => {
  try {
    if (!selectedOption) {
      throw new Error('Selected option is required');
    }

    const proposalRef = doc(db, 'proposals', proposalId);

    // Get current proposal data
    const proposalDoc = await getDoc(proposalRef);
    if (!proposalDoc.exists()) {
      throw new Error('Proposal not found');
    }

    const currentData = proposalDoc.data();
    const currentOptions = Array.isArray(currentData.proposedOptions) ? currentData.proposedOptions : [];

    // Add the new option to the existing array
    const updatedOptions = [...currentOptions, selectedOption];

    // Update with the new array
    await updateDoc(proposalRef, {
      proposedOptions: updatedOptions
    });

    // Fetch the updated proposal data
    const updatedDoc = await getDoc(proposalRef);
    if (!updatedDoc.exists()) {
      throw new Error('Proposal not found after update');
    }

    return {
      success: true,
      updatedProposal: updatedDoc.data()
    };
  } catch (error) {
    console.error('Error adding option to proposal:', error);
    return {
      success: false,
      error: error.message || 'Error adding option to proposal'
    };
  }
};

export const createProposal = async (clientName = 'Enter client name') => {
  try {
    const proposalsRef = collection(db, 'proposals');
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    }) + ' UTC+5:30';

    const newProposal = {
      clientName,
      city: '',
      requirements: 'Enter requirements',
      proposedOptions: [],
      status: 'Draft',
      proposalDate: formattedDate,
      createdAt: formattedDate,
      lastModified: formattedDate,
      viewers: [],
      shortlist: []
    };

    const docRef = await addDoc(proposalsRef, newProposal);
    return {
      success: true,
      proposalId: docRef.id
    };
  } catch (error) {
    console.error('Error creating proposal:', error);
    return {
      success: false,
      error: error.message || 'Error creating proposal'
    };
  }
};

export const fetchOperators = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "operators"));
    const operatorsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Extract unique operator types
    const operatorTypes = [...new Set(operatorsList.map(operator => operator.operatorType))].filter(Boolean).sort();

    return {
      operators: operatorsList,
      operatorTypes: operatorTypes
    };
  } catch (error) {
    console.error("Error fetching operators:", error);
    return {
      operators: [],
      operatorTypes: []
    };
  }
};

export const fetchCenters = async () => {
  try {
    // Fetch centers
    const centersSnapshot = await getDocs(collection(db, "centers"));
    const centersList = centersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Fetch operators
    const operatorsSnapshot = await getDocs(collection(db, "operators"));
    const operatorsList = operatorsSnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().operatorName,  // This is what we'll display in the dropdown
      ...doc.data()
    }));

    // Extract unique cities
    const cities = [...new Set(centersList.map(center => center.city))].filter(Boolean).sort();

    return {
      centers: centersList,
      operators: operatorsList,  // Now contains full operator objects
      cities: cities
    };
  } catch (error) {
    console.error("Error fetching centers:", error);
    return {
      centers: [],
      operators: [],
      cities: []
    };
  }
};

export const fetchCentersByOperator = async (operatorId) => {
  try {
    const centersRef = collection(db, "centers");
    const q = query(centersRef, where("operatorId", "==", operatorId));
    const querySnapshot = await getDocs(q);

    const centersList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return {
      success: true,
      centers: centersList
    };
  } catch (error) {
    console.error('Error fetching centers for operator:', error);
    return {
      success: false,
      error: error.message
    };
  }
};

export const fetchInventories = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "inventories"));
    const inventoriesList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Extract unique values for filters
    const operators = [...new Set(inventoriesList.map(inv => inv.operator))].filter(Boolean).sort();
    const centers = [...new Set(inventoriesList.map(inv => inv.centerName))].filter(Boolean).sort();
    const cities = [...new Set(inventoriesList.map(inv => inv.city))].filter(Boolean).sort();
    const sizes = inventoriesList.map(inv => parseInt(inv.size)).filter(Boolean);

    return {
      inventories: inventoriesList,
      operators,
      centers,
      cities,
      sizeRange: {
        min: Math.min(...sizes),
        max: Math.max(...sizes)
      }
    };
  } catch (error) {
    console.error("Error fetching inventories:", error);
    return {
      inventories: [],
      operators: [],
      centers: [],
      cities: [],
      sizeRange: { min: 0, max: 0 }
    };
  }
};

export const fetchInventoriesByOperator = async (operatorId) => {
  try {
    const inventoriesRef = collection(db, "inventories");
    const q = query(inventoriesRef, where("operatorId", "==", operatorId));
    const querySnapshot = await getDocs(q);

    const inventoriesList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return {
      success: true,
      inventories: inventoriesList
    };
  } catch (error) {
    console.error('Error fetching inventories for operator:', error);
    return {
      success: false,
      error: error.message
    };
  }
};

export const publishProposal = async (proposalId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      status: 'Published',
      lastModified: new Date().toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'Asia/Kolkata'
      }) + ' UTC+5:30'
    });
    // Generate the public URL with the correct format
    const publicUrl = `${window.location.origin}/public/published-proposal/?proposalId=${proposalId || ''}`;
    return {
      success: true,
      publicUrl
    };
  } catch (error) {
    console.error('Error publishing proposal:', error);
    return {
      success: false,
      error: error.message || 'Error publishing proposal'
    };
  }
};

export const getProposalStatus = async (proposalId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    const proposalDoc = await getDoc(proposalRef);

    if (!proposalDoc.exists()) {
      return {
        success: false,
        error: 'Proposal not found'
      };
    }

    const proposalData = proposalDoc.data();
    return {
      success: true,
      status: proposalData.status,
      isPublished: proposalData.status === 'Published'
    };
  } catch (error) {
    console.error('Error getting proposal status:', error);
    return {
      success: false,
      error: error.message || 'Error getting proposal status'
    };
  }
};

export const unpublishProposal = async (proposalId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      status: 'Draft',
      lastModified: new Date().toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'Asia/Kolkata'
      }) + ' UTC+5:30'
    });
    return { success: true };
  } catch (error) {
    console.error('Error unpublishing proposal:', error);
    return {
      success: false,
      error: error.message || 'Error unpublishing proposal'
    };
  }
};

export const deleteProposal = async (proposalId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    await deleteDoc(proposalRef);
    return { success: true };
  } catch (error) {
    console.error('Error deleting proposal:', error);
    return {
      success: false,
      error: error.message || 'Error deleting proposal'
    }
  }
}

export const addShortlistNotification = async (proposalId, userEmail, centerName) => {
  try {
    const notificationsRef = collection(db, 'notifications');
    const now = new Date();
    const timestamp = now.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    }).replace(',', '');

    const notification = {
      type: 'shortlist',
      proposalId,
      userEmail: userEmail || 'Anonymous User',
      centerName,
      timestamp,
    };

    await addDoc(notificationsRef, notification);
    return { success: true };
  } catch (error) {
    console.error('Error adding shortlist notification:', error);
    return {
      success: false,
      error: error.message || 'Error adding shortlist notification'
    };
  }
};

export const addVisitNotification = async (proposalId, userEmail, centerName, visitDate, visitTime) => {
  try {
    const notificationsRef = collection(db, 'notifications');
    const now = new Date();
    const timestamp = now.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    }).replace(',', '');

    const notification = {
      type: 'visit',
      proposalId,
      userEmail: userEmail || 'Anonymous User',
      centerName,
      visitDate,
      visitTime,
      timestamp,
    };

    await addDoc(notificationsRef, notification);
    return { success: true };
  } catch (error) {
    console.error('Error adding visit notification:', error);
    return {
      success: false,
      error: error.message || 'Error adding visit notification'
    };
  }
};

export const getNotifications = async () => {
  try {
    const notificationsRef = collection(db, 'notifications');
    const querySnapshot = await getDocs(notificationsRef);
    const notifications = [];

    querySnapshot.forEach((doc) => {
      notifications.push({
        ...doc.data(),
        docId: doc.id // Store Firestore document ID for updates
      });
    });

    // Sort notifications by timestamp in descending order
    notifications.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    return { success: true, notifications };
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return {
      success: false,
      error: error.message || 'Error fetching notifications'
    };
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, {
      read: true
    });
    return { success: true };
  } catch (error) {
    console.error('Error marking notification as read:', error);
    return {
      success: false,
      error: error.message || 'Error marking notification as read'
    };
  }
};

export const markNotesAsRead = async (proposalId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      notesRead: true
    });
    return { success: true };
  } catch (error) {
    console.error('Error marking notes as read:', error);
    return {
      success: false,
      error: error.message || 'Error marking notes as read'
    };
  }
};

export const addOperator = async (formData) => {
  try {
    const now = new Date();
    const formattedTimestamp = now.getFullYear() + '-' +
      String(now.getMonth() + 1).padStart(2, '0') + '-' +
      String(now.getDate()).padStart(2, '0') + ' ' +
      String(now.getHours()).padStart(2, '0') + ':' +
      String(now.getMinutes()).padStart(2, '0') + ':' +
      String(now.getSeconds()).padStart(2, '0') + '.0';

    const { registeredAddress } = formData;

    // Format registered address as a string
    const formattedAddress = registeredAddress ?
      `${registeredAddress.line1 || ''}, ${registeredAddress.line2 || ''}, ${registeredAddress.city || ''}, ${registeredAddress.state || ''}, ${registeredAddress.postalCode || ''}, ${registeredAddress.country || ''}`.replace(/,\s*,/g, ',').replace(/^,\s*/, '').replace(/,\s*$/, '')
      : "";

    const operatorData = {
      addedTime: formattedTimestamp,
      addedUser: "gofloaters",
      annualEscalation: Number(formData.annualEscalation) || 7,
      centralSpocEmail: formData.centralSpocEmail || "",
      centralSpocName: formData.centralSpocName || "",
      centralSpocPhoneNumber: Number(formData.centralSpocPhone?.replace(/\D/g, '')) || 919884160875,
      corporateProfile: "",
      deposit: Number(formData.deposit) || 6,
      lockinPeriod: Number(formData.lockinPeriod) || 24,
      modifiedTime: formattedTimestamp,
      modifiedUser: "sundarns8", // Default modified user
      noticePeriod: Number(formData.noticePeriod) || 6,
      operatorDetails: "",
      operatorName: formData.operatorName,
      operatorType: formData.operatorType,
      recordStatus: "UNLOCKED",
      recordType: "Operator",
      registeredAddress: formattedAddress,
      standardTerms: ""
    };

    const docRef = await addDoc(collection(db, 'operators'), operatorData);

    // Update the document with its ID
    await updateDoc(doc(db, 'operators', docRef.id), {
      id: docRef.id
    });

    return { success: true, id: docRef.id };
  } catch (error) {
    console.error('Error adding operator:', error);
    return { success: false, error: error.message };
  }
};

export const updateOperator = async (operatorId, formData) => {
  try {
    const operatorRef = doc(db, 'operators', operatorId);
    await updateDoc(operatorRef, {
      ...formData,
      lastModified: serverTimestamp()
    });

    return {
      success: true,
      message: 'Operator updated successfully'
    };
  } catch (error) {
    console.error('Error updating operator:', error);
    return {
      success: false,
      error: error.message || 'Error updating operator'
    };
  }
};

export const deleteOperator = async (operatorId) => {
  try {
    const operatorRef = doc(db, 'operators', operatorId);
    await deleteDoc(operatorRef);

    return {
      success: true,
      message: 'Operator deleted successfully'
    };
  } catch (error) {
    console.error('Error deleting operator:', error);
    return {
      success: false,
      error: error.message || 'Error deleting operator'
    };
  }
};

export const updateCenter = async (centerId, formData) => {
  try {
    const now = new Date();
    const formattedTimestamp = now.getFullYear() + '-' +
      String(now.getMonth() + 1).padStart(2, '0') + '-' +
      String(now.getDate()).padStart(2, '0') + ' ' +
      String(now.getHours()).padStart(2, '0') + ':' +
      String(now.getMinutes()).padStart(2, '0') + ':' +
      String(now.getSeconds()).padStart(2, '0') + '.0';

    const centerData = {
      modifiedTime: formattedTimestamp,
      centerName: formData.centerName,
      operatorName: formData.operatorName,
      city: formData.city,
      micromarket: formData.micromarket,
      address: formData.address,
      locality: formData.locality,
      landmark: formData.landmark,
      googleMapsLocation: formData.googleMapsLocation,
      centerManagerName: formData.centerManagerName || "",
      centerManagerPhone: formData.centerManagerPhone || "",
      centerManagerEmail: formData.centerManagerEmail || "",
      operationalFrom: formData.operationalFrom || "",
      description: formData.description || "",
      buildingType: formData.buildingType || "",
      buildingGrade: formData.buildingGrade || "",
      nearestMetro: formData.nearestMetro || "",
      operatingHours: formData.operatingHours || "",
      latitude: formData.latitude || "",
      longitude: formData.longitude || "",
      canSupport24x7: formData.canSupport24x7 || "",
      remarks: formData.remarks || "",
      amenities: formData.amenities ? formData.amenities : [],
      cafeteriaCapacity: formData.cafeteriaCapacity || "",
      listPrice: formData.listPrice || "",
      closingPrice: formData.closingPrice || "",
      carParkingCost: formData.carParkingCost || "",
      bikeParkingCost: formData.bikeParkingCost || "",
      active: formData.active || false,
      photos: formData.photos || [],
      virtualTours: formData.virtualTours || [],
    };

    const centerRef = doc(db, 'centers', centerId);
    await updateDoc(centerRef, centerData);

    return {
      success: true,
      message: 'Center updated successfully'
    };
  } catch (error) {
    console.error('Error updating center:', error);
    return {
      success: false,
      error: error.message || 'Error updating center'
    };
  }
};

export const addCenter = async (centerData, operatorId) => {
  try {
    const centersRef = collection(db, 'centers');
    const timestamp = Date.now();

    const newCenterData = {
      active: "true",
      addedTime: timestamp,
      addedUser: "gofloaters", // Default user
      additionalDetails: "",
      address: centerData.address || "",
      amenities: [
        centerData.amenities.recreationalSpace ? "Recreational Space" : null,
        centerData.amenities.sharedInternet ? "Shared Internet" : null,
        centerData.amenities.meetingRooms ? "Common Conference Rooms" : null,
        centerData.amenities.vendingMachine ? "Vending Machine" : null,
        centerData.amenities.accessCardSystem ? "Access Card System" : null,
        centerData.amenities.gym ? "Gym" : null,
        centerData.amenities.foodCourt ? "Food Court" : null,
        centerData.amenities.wellnessRoom ? "Wellness Room" : null
      ].filter(Boolean),
      bikeParkingCost: centerData.bikeParkingCost || "",
      buildingGrade: centerData.buildingGrade || "Grade B",
      buildingType: centerData.buildingType || "Floor in Commercial Building",
      cafeteriaCapacity: centerData.cafeteriaCapacity || "",
      canSupport247: centerData.canSupport247 || "",
      carParkingCost: centerData.carParkingCost || "",
      centerDetails: "",
      centerManagerEmail: centerData.centerManagerEmail || "",
      centerManagerName: centerData.centerManagerName || "",
      centerManagerPhone: centerData.centerManagerPhone ? Number(centerData.centerManagerPhone) : "",
      centerName: centerData.centerName || "",
      city: centerData.city || "",
      closingPrice: centerData.closingPrice || "",
      description: centerData.description || "",
      googleMapsLocation: centerData.googleMapsLocation || "",
      gym: "false",
      landmark: centerData.landmark || "",
      latitude: centerData.latitude ? Number(centerData.latitude) : 0,
      listPrice: centerData.listPrice ? Number(centerData.listPrice) : 0,
      locality: centerData.locality || "",
      longitude: centerData.longitude ? Number(centerData.longitude) : 0,
      lookBook: "",
      micromarket: centerData.micromarket || "",
      modifiedTime: timestamp,
      modifiedUser: "sundarns8", // Default modified user
      nearestMetro: centerData.nearestMetro || "None",
      operatingHours: centerData.operatingHours || "",
      operationalFrom: centerData.operationalFrom || "",
      operatorId: operatorId || "",
      operatorName: centerData.operatorName || "",
      photos: centerData.photos || [],
      pricing: "",
      recordStatus: "UNLOCKED",
      recordType: "Center",
      remarks: ""
    };

    const docRef = await addDoc(centersRef, newCenterData);

    // Update the document with its Firebase ID
    await updateDoc(doc(db, 'centers', docRef.id), {
      id: docRef.id
    });

    return {
      success: true,
      centerId: docRef.id
    };
  } catch (error) {
    console.error('Error adding center:', error);
    return {
      success: false,
      error: error.message || 'Error adding center'
    };
  }
};

export const addInventory = async (inventoryData) => {
  try {
    console.log('Adding inventory with data:', inventoryData);
    const timestamp = Date.now();

    const inventoryToAdd = {
      // Base fields
      active: inventoryData.active || true,
      addedTime: timestamp,
      addedUser: "admin", // TODO: Replace with actual user
      modifiedTime: timestamp,
      modifiedUser: "admin", // TODO: Replace with actual user
      recordStatus: "UNLOCKED",
      recordType: "Inventory",

      // Number fields with proper parsing
      quantity: parseInt(inventoryData.quantity) || 1,
      size: parseInt(inventoryData.size) || 0,

      // Form fields with proper fallbacks
      address: inventoryData.address || "",
      availabilityDate: inventoryData.availabilityDate || new Date().toISOString().split('T')[0],
      cabinNumber: inventoryData.cabinNumber || "",
      centerDetails: inventoryData.centerDetails || "",
      centerId: inventoryData.centerId || "",
      centerName: inventoryData.centerName || "",
      city: inventoryData.city || "",
      description: inventoryData.description || "",
      floor: inventoryData.floor || "",
      googleMapsUrl: inventoryData.googleMapsUrl || "",
      inventoryDetails: inventoryData.inventoryDetails || "",
      inventoryName: inventoryData.inventoryName || "",
      inventoryType: inventoryData.inventoryType || "",
      lastUpdatedDate: inventoryData.lastUpdatedDate || new Date().toISOString().split('T')[0],
      locality: inventoryData.locality || "",
      micromarket: inventoryData.micromarket || "",
      operatorId: inventoryData.operatorId || "",
      operatorName: inventoryData.operatorName || "",
      section: inventoryData.section || "",
      
      // Arrays with proper initialization
      amenities: Array.isArray(inventoryData.amenities) ? inventoryData.amenities : [],
      photos: Array.isArray(inventoryData.photos) ? inventoryData.photos : []
    };

    console.log('Processed inventory data:', inventoryToAdd);

    const docRef = await addDoc(collection(db, "inventories"), inventoryToAdd);
    await updateDoc(doc(db, 'inventories', docRef.id), {
      id: docRef.id
    });

    console.log('Successfully added inventory with ID:', docRef.id);

    return { 
      success: true,
      message: 'Inventory added successfully',
      inventoryData: inventoryToAdd,
      inventoryId: docRef.id 
    };
  } catch (error) {
    console.error("Error adding inventory:", error);
    return { 
      success: false, 
      error: error.message,
      details: error.stack 
    };
  }
};

export const updateInventory = async (inventoryId, inventoryData) => {
  try {
    if (!inventoryId) {
      throw new Error('Inventory ID is required');
    }

    console.log('Updating inventory:', { inventoryId, inventoryData });
    const inventoryRef = doc(db, 'inventories', inventoryId);

    // Ensure ID fields are consistent
    const updateData = {
      ...inventoryData,
      inventoryId,
      id: inventoryId,
      lastUpdatedDate: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      active: true
    };

    console.log('Final update data:', updateData);

    // Use setDoc with merge option to preserve existing fields
    await setDoc(inventoryRef, updateData, { merge: true });

    console.log('Inventory updated successfully:', inventoryId);
    return { success: true, inventoryId };
  } catch (error) {
    console.error('Error in updateInventory:', error);
    throw error;
  }
};

export const deleteInventory = async (inventoryId) => {
  try {
    const inventoryRef = doc(db, 'inventories', inventoryId);
    await deleteDoc(inventoryRef);

    return {
      success: true,
      message: 'Inventory deleted successfully'
    };
  } catch (error) {
    console.error('Error deleting inventory:', error);
    return {
      success: false,
      error: error.message || 'Error deleting inventory'
    };
  }
};

export const reorderCenters = async (proposalId, proposedOptions) => {
  try {
    if (!proposalId) {
      throw new Error('Proposal ID is required');
    }

    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      proposedOptions: proposedOptions,
      lastUpdated: serverTimestamp()
    });

    return {
      success: true,
      updatedOptions: proposedOptions
    };
  } catch (error) {
    console.error('Error reordering centers:', error);
    return {
      success: false,
      error: error.message || 'Error reordering centers'
    };
  }
};

export const deleteCenterPhoto = async (centerId, photoUrl) => {
  try {
    // Delete from Firebase Storage
    const photoRef = ref(storage, photoUrl);
    await deleteObject(photoRef);

    // Get current center data to check photo count
    const centerRef = doc(db, 'centers', centerId);
    const centerDoc = await getDoc(centerRef);
    const centerData = centerDoc.data();

    // Remove the specific photo
    await updateDoc(centerRef, {
      photos: arrayRemove({ url: photoUrl })
    });

    // If no photos remain, remove the photos field entirely
    if (centerData.photos && centerData.photos.length <= 1) {
      await updateDoc(centerRef, {
        photos: deleteField() // Remove the entire photos field
      });
    }

    return { success: true, message: 'Photo deleted successfully' };
  } catch (error) {
    console.error('Error deleting center photo:', error);
    return {
      success: false,
      message: error.message || 'Failed to delete photo'
    };
  }
}

export const deleteInventoryPhoto = async (inventoryId, photoUrl) => {
  try {
    // Delete from Firebase Storage
    const photoRef = ref(storage, photoUrl);
    await deleteObject(photoRef);

    // Get current center data to check photo count
    const inventoryRef = doc(db, 'inventories', inventoryId);
    const inventoryDoc = await getDoc(inventoryRef);
    const inventroyData = inventoryDoc.data();

    // Remove the specific photo
    await updateDoc(inventoryRef, {
      photos: arrayRemove({ url: photoUrl })
    });

    // If no photos remain, remove the photos field entirely
    if (inventroyData.photos && inventroyData.photos.length <= 1) {
      await updateDoc(inventoryRef, {
        photos: deleteField() // Remove the entire photos field
      });
    }

    return { success: true, message: 'Photo deleted successfully' };
  } catch (error) {
    console.error('Error deleting center photo:', error);
    return {
      success: false,
      message: error.message || 'Failed to delete photo'
    };
  }
}

export const fetchCenterById = async (centerId) => {
  try {
    const centerDoc = await getDoc(doc(db, 'centers', centerId));
    if (!centerDoc.exists()) {
      throw new Error('Center not found');
    }
    return centerDoc.data();
  } catch (error) {
    console.error('Error fetching center:', error);
    throw error;
  }
};

export const fetchOperatorById = async (operatorId) => {
  try {
    const operatorDoc = await getDoc(doc(db, 'operators', operatorId));
    if (!operatorDoc.exists()) {
      throw new Error('Operator not found');
    }
    return operatorDoc.data();
  } catch (error) {
    console.error('Error fetching operator:', error);
    throw error;
  }
};

export const checkInventoryAvailability = async (inventory) => {
  try {
    if (!inventory.centerId) {
      throw new Error('Center ID not found in inventory data');
    }

    const centerRef = doc(db, 'centers', inventory.centerId);
    const centerDoc = await getDoc(centerRef);

    if (!centerDoc.exists()) {
      throw new Error('Center not found');
    }

    const centerData = centerDoc.data();
    const managerPhone = "+91" + centerData.centerManagerPhone;

    if (!managerPhone) {
      throw new Error('Manager phone number not found');
    }

    // VAPI API call configuration
    const vapiEndpoint = 'https://api.vapi.ai/call';
    const vapiKey = process.env.REACT_APP_VAPI_KEY;

    const response = await fetch(vapiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${vapiKey}`
      },
      body: JSON.stringify({
        customer: {
          number: managerPhone,
          name: "Partner"
        },
        assistant: {
          name: "Availability Checker",
          model: "gpt-4o-mini",
          voice: {
            provider: "cartesia",
            voiceId: "ff1bb1a9-c582-4570-9670-5f46169d0fc8"
          },
          firstMessage: `Hi ${centerData.centerManagerName}, I'm calling from GoFloaters to check the availability of an inventory in your building. 
          The inventory details are:
          - Building Name: ${inventory.centerName}
          - Inventory Type: ${inventory.inventoryType}
          - Capacity: ${inventory.size} seats
          We have a customer enquiry for this inventory. Could you please confirm if this specific inventory is currently available?`,
          backgroundSound: "office",
          server: {
            url: "https://us-central1-gofloaters-proposal-dev.cloudfunctions.net/vapiWebhook",
            headers: {
              "Content-Type": "application/json"
            }
          },
          serverMessages: ['end-of-call-report'],
          analysisPlan: {
            structuredDataPlan: {
              messages: [
                {
                  role: "system",
                  content: "You are an expert data extractor. You will be given a transcript of a call. Extract structured data per the JSON Schema. DO NOT return anything except the structured data. Json Schema:{{schema}}. inventoryAvailable will be either 'yes' or 'no'. If the inventory is available, respond with 'yes'. If the inventory is not available, respond with 'no'."
                },
                {
                  role: "user",
                  content: "Here is the transcript: {{transcript}}"
                }
              ],
              schema: {
                type: "object",
                properties: {
                  inventoryAvailable: {
                    type: "string",
                    enum: ["yes", "no"]
                  }
                },
                required: ["inventoryAvailable"]
              },
              enabled: true
            }
          }
        },
        phoneNumber: {
          twilioPhoneNumber: "+12076302746",
          twilioAccountSid: "ACe0e903156120389dbf0c3fd4b798cf5d",
          twilioAuthToken: "6f3a71d9dde3e3e74e150bcf65ad4ba1"
        }
      })
    });

    const data = await response.json();
    console.log("Vapi response:", data);
    if (!response.ok) {
      throw new Error(data.message || 'Failed to initiate call');
    }

    // Update inventory status to in-progress
    const inventoryRef = doc(db, 'inventories', inventory.id);
    await updateDoc(inventoryRef, {
      inventoryCheckStatus: 'inprogress',
      lastCallInitiatedTime: serverTimestamp(),
      callId: data.id
    });

    return {
      success: true,
      callId: data.call_id,
      message: 'Call initiated successfully'
    };

  } catch (error) {
    console.error('Error checking inventory availability:', error);
    return {
      success: false,
      error: error.message || 'Failed to check availability'
    };
  }
};

export const addLead = async (leadData) => {
  try {
    const leadsCollection = collection(db, 'leads');
    const docRef = await addDoc(leadsCollection, {
      ...leadData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error('Error adding lead:', error);
    return { success: false, error: error.message };
  }
};

export const updateLead = async (leadId, leadData) => {
  try {
    const leadRef = doc(db, 'leads', leadId);
    await updateDoc(leadRef, {
      ...leadData,
      updatedAt: serverTimestamp()
    });
    return { success: true };
  } catch (error) {
    console.error('Error updating lead:', error);
    return { success: false, error: error.message };
  }
};

export const deleteLead = async (leadId) => {
  try {
    const leadRef = doc(db, 'leads', leadId);
    await deleteDoc(leadRef);
    return { success: true };
  } catch (error) {
    console.error('Error deleting lead:', error);
    return { success: false, error: error.message };
  }
};

export const setupLeadsListener = (email, isAdmin, callback) => {
  try {
    let q;
    console.log('Setting up leads listener:', { email, isAdmin });

    if (isAdmin) {
      q = query(collection(db, 'leads'), orderBy('createdAt', 'desc'));
      console.log('Admin query created for leads');
    } else {
      // For non-admin users, we need to create a composite index
      console.log('Creating non-admin query for leads with owner:', email);
      q = query(
        collection(db, 'leads'),
        where('owner', '==', email),
        orderBy('createdAt', 'desc')
      );
    }

    return onSnapshot(q, (snapshot) => {
      const leads = snapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Lead document:', { id: doc.id, owner: data.owner });
        return convertTimestampsToDates({
          id: doc.id,
          ...data
        });
      });
      console.log(`Received ${leads.length} leads for ${isAdmin ? 'admin' : 'user'}`);
      callback(leads);
    }, (error) => {
      console.error('Error in leads listener:', error);
      callback([]);
    });
  } catch (error) {
    console.error('Error setting up leads listener:', error);
    return () => { };
  }
};

export const setupActionItemsListener = (email, isAdmin, callback) => {
  try {
    let q;
    console.log('Setting up action items listener:', { email, isAdmin });

    if (isAdmin) {
      q = query(collection(db, 'actionItems'), orderBy('createdAt', 'desc'));
      console.log('Admin query created for action items');
    } else {
      console.log('Creating non-admin query for action items with owner:', email);
      q = query(
        collection(db, 'actionItems'),
        where('owner', '==', email),
        orderBy('createdAt', 'desc')
      );
    }

    return onSnapshot(q, (snapshot) => {
      const items = snapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Action item document:', { id: doc.id, owner: data.owner });
        return convertTimestampsToDates({
          id: doc.id,
          ...data
        });
      });
      console.log(`Received ${items.length} action items for ${isAdmin ? 'admin' : 'user'}`);
      callback(items);
    }, (error) => {
      console.error('Error in action items listener:', error);
      callback([]);
    });
  } catch (error) {
    console.error('Error setting up action items listener:', error);
    return () => { };
  }
};

export const setupProposalsListener = (email, isAdmin, callback) => {
  try {
    let q;
    console.log('Setting up proposals listener:', { email, isAdmin });

    if (isAdmin) {
      q = query(collection(db, 'proposals'), orderBy('createdAt', 'desc'));
      console.log('Admin query created for proposals');
    } else {
      console.log('Creating non-admin query for proposals with owner:', email);
      q = query(
        collection(db, 'proposals'),
        where('owner', '==', email),
        orderBy('createdAt', 'desc')
      );
    }

    return onSnapshot(q, (snapshot) => {
      const proposals = snapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Proposal document:', { id: doc.id, owner: data.owner });
        return convertTimestampsToDates({
          id: doc.id,
          ...data
        });
      });
      console.log(`Received ${proposals.length} proposals for ${isAdmin ? 'admin' : 'user'}`);
      callback(proposals);
    }, (error) => {
      console.error('Error in proposals listener:', error);
      callback([]);
    });
  } catch (error) {
    console.error('Error setting up proposals listener:', error);
    return () => { };
  }
};

export const updateLeadProfile = async (leadId, profile) => {
  try {
    const leadRef = doc(db, 'leads', leadId);
    await updateDoc(leadRef, {
      profile: {
        ...profile,
        generatedAt: serverTimestamp()
      }
    });
    return { success: true };
  } catch (error) {
    console.error('Error updating lead profile:', error);
    return { success: false, error: error.message };
  }
};

// Action Items Functions
export const addActionItem = async (actionItem) => {
  try {
    const actionItemsRef = collection(db, 'actionItems');
    const docRef = await addDoc(actionItemsRef, {
      ...actionItem,
      createdAt: serverTimestamp(),
      status: 'open', // Default status
      dueDate: actionItem.dueDate ? actionItem.dueDate : null
    });
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error('Error adding action item:', error);
    return { success: false, error: error.message };
  }
};

export const updateActionItem = async (actionItemId, updates) => {
  try {
    const actionItemRef = doc(db, 'actionItems', actionItemId);
    const updateData = {
      ...updates,
      updatedAt: serverTimestamp()
    };

    // If dueDate is being updated, ensure it's a proper Firestore timestamp
    if (updates.dueDate !== undefined) {
      updateData.dueDate = updates.dueDate ? updates.dueDate : null;
    }

    await updateDoc(actionItemRef, updateData);
    return { success: true };
  } catch (error) {
    console.error('Error updating action item:', error);
    return {
      success: false,
      error: error.message || 'Error updating action item'
    };
  }
};

export const deleteActionItem = async (actionItemId) => {
  try {
    const actionItemRef = doc(db, 'actionItems', actionItemId);
    await deleteDoc(actionItemRef);
    return { success: true };
  } catch (error) {
    console.error('Error deleting action item:', error);
    return {
      success: false,
      error: error.message || 'Error deleting action item'
    };
  }
};

export const fetchLeads = async () => {
  try {
    const leadsRef = collection(db, 'leads');
    const snapshot = await getDocs(leadsRef);
    const leads = [];
    snapshot.forEach((doc) => {
      leads.push({
        id: doc.id,
        ...doc.data()
      });
    });
    return { success: true, leads };
  } catch (error) {
    console.error('Error fetching leads:', error);
    return { success: false, error: error.message };
  }
};

export const fetchProposals = async () => {
  try {
    const proposalsRef = collection(db, 'proposals');
    const snapshot = await getDocs(proposalsRef);
    const proposals = [];
    snapshot.forEach((doc) => {
      proposals.push({
        id: doc.id,
        ...doc.data()
      });
    });
    return { success: true, proposals };
  } catch (error) {
    console.error('Error fetching proposals:', error);
    return { success: false, error: error.message };
  }
};

export const getQuestionsByInventoryId = async (inventoryId) => {
  try {
    const q = query(
      collection(db, 'questions'),
      where('inventoryId', '==', inventoryId),
      orderBy('timestamp', 'desc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting questions:', error);
    return [];
  }
};

export const getProposal = async (proposalId) => {
  try {
    const proposalDoc = await getDoc(doc(db, 'proposals', proposalId));
    if (proposalDoc.exists()) {
      return { id: proposalDoc.id, ...proposalDoc.data() };
    }
    return null;
  } catch (error) {
    console.error('Error getting proposal:', error);
    return null;
  }
};

export const setupUserListener = (email, callback) => {
  try {
    const q = query(collection(db, 'users'), where('email', '==', email));
    return onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        // Get the first matching user document
        const userDoc = snapshot.docs[0];
        callback({
          id: userDoc.id,
          ...userDoc.data()
        });
      } else {
        console.log('No user found with email:', email);
        callback(null);
      }
    }, (error) => {
      console.error('Error in user listener:', error);
      callback(null);
    });
  } catch (error) {
    console.error('Error setting up user listener:', error);
    return () => { };
  }
};

export const setupProposalListener = (proposalId, callback) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);

    // Set up real-time listener
    const unsubscribe = onSnapshot(proposalRef, (doc) => {
      if (doc.exists()) {
        const proposalData = { id: doc.id, ...doc.data() };
        callback(proposalData);
      } else {
        callback(null);
      }
    }, (error) => {
      console.error('Error listening to proposal:', error);
      callback(null);
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error setting up proposal listener:', error);
    return () => { };
  }
};

export const scheduleVisit = async (proposalId, centerId, scheduledDate, scheduledTime, scheduledByEmail) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);

    // Get proposal data to access owner and client name
    const proposalSnap = await getDoc(proposalRef);
    const proposalData = proposalSnap.data();

    // Create timestamp for scheduledAt (current time)
    const scheduledAt = Timestamp.fromDate(moment().toDate());

    // Create timestamp for scheduledFor (selected date and time)
    const [hours, minutes] = scheduledTime.split(':');
    const scheduledForMoment = moment(scheduledDate)
      .hours(parseInt(hours))
      .minutes(parseInt(minutes))
      .seconds(0)
      .milliseconds(0);
    const scheduledFor = Timestamp.fromDate(scheduledForMoment.toDate());

    const scheduleData = {
      inventoryId: centerId,
      scheduledAt: scheduledAt,
      scheduledBy: scheduledByEmail,
      scheduledFor: scheduledFor
    };

    // Get all existing visit schedules from the proposal
    const existingSchedules = proposalData.visitSchedule || [];

    // Find if there's an existing schedule for this inventory
    const existingIndex = existingSchedules.findIndex(
      schedule => schedule.inventoryId === centerId
    );

    let updatedSchedules;
    if (existingIndex !== -1) {
      // Update existing schedule
      updatedSchedules = [...existingSchedules];
      updatedSchedules[existingIndex] = scheduleData;
    } else {
      // Add new schedule
      updatedSchedules = [...existingSchedules, scheduleData];
    }

    // Update the proposal with the new schedule array
    await updateDoc(proposalRef, {
      visitSchedule: updatedSchedules
    });

    // Create action item for the proposal owner
    const actionItem = {
      title: `Visit scheduled for ${centerId} by ${proposalData.clientName}`,
      description: `${scheduledByEmail} has scheduled a visit for ${centerId}`,
      dueDate: scheduledFor,
      owner: proposalData.owner,
      assignedTo: 'user',
      linkTo: "Proposal",
      proposalId: proposalId,
      proposalName: proposalData.clientName
    };

    await addActionItem(actionItem);

    // Add notification for the visit schedule
    await addVisitNotification(
      proposalId,
      scheduledByEmail,
      centerId,
      scheduledDate,
      scheduledTime
    );

    return { success: true };
  } catch (error) {
    console.error('Error scheduling visit:', error);
    return { success: false, error: error.message };
  }
};

export const deleteVisitSchedule = async (proposalId, centerId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    const proposalSnap = await getDoc(proposalRef);
    const proposalData = proposalSnap.data();

    // Filter out the schedule for this centerId
    const updatedSchedules = proposalData.visitSchedule?.filter(
      schedule => schedule.inventoryId !== centerId
    ) || [];

    await updateDoc(proposalRef, {
      visitSchedule: updatedSchedules
    });

    return { success: true };
  } catch (error) {
    console.error('Error deleting visit schedule:', error);
    return { success: false, error: error.message };
  }
};

export const fetchMetaDocument = async () => {
  try {
    const metaCollection = collection(db, 'meta');
    const q = query(metaCollection, limit(1));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return { success: true, data: { id: doc.id, ...doc.data() } };
    }

    return { success: true, data: null };
  } catch (error) {
    console.error('Error fetching meta document:', error);
    return { success: false, error: error.message };
  }
};

export const saveQuestionAnswer = async (proposalId, questionIndex, answer, answeredBy, centerId) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    const proposalDoc = await getDoc(proposalRef);

    if (!proposalDoc.exists()) {
      throw new Error('Proposal not found');
    }

    const proposalData = proposalDoc.data();
    const feedback = [...(proposalData.feedback || [])];
    const now = new Date();
    const formattedTimestamp = now.getFullYear() + '-' +
      String(now.getMonth() + 1).padStart(2, '0') + '-' +
      String(now.getDate()).padStart(2, '0') + ' ' +
      String(now.getHours()).padStart(2, '0') + ':' +
      String(now.getMinutes()).padStart(2, '0') + ':' +
      String(now.getSeconds()).padStart(2, '0') + '.0';
    const updatedFeedback = feedback.map((question, index) => {
      if (question.inventoryId === centerId) {
        // Update the specific question in the feedback array
        question.feedbackItem[questionIndex] = {
          ...question.feedbackItem[questionIndex],
          answer,
          answeredBy: {
            email: answeredBy,
            timestamp: formattedTimestamp
          }
        };
      }
      return question;
    });


    // Update the entire feedback array in the proposal
    await updateDoc(proposalRef, {
      feedback: updatedFeedback
    });

    return { success: true };
  } catch (error) {
    console.error('Error saving question answer:', error);
    return { success: false, error: error.message };
  }
};

export const getMetaDocument = async () => {
  const metaCollection = collection(db, 'meta');
  const q = query(metaCollection, limit(1));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0];
  }
  return null;
};

export const subscribeToMetaData = (onData, onError) => {
  const metaCollection = collection(db, 'meta');
  const q = query(metaCollection, limit(1));
  
  return onSnapshot(q, (querySnapshot) => {
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      onData({
        cities: data.cities || [],
        micromarkets: data.micromarkets || []
      });
    }
  }, onError);
};

export const addCity = async (cityName, micromarkets) => {
  const metaDoc = await getMetaDocument();
  if (!metaDoc) throw new Error('Meta document not found');
  
  const metaRef = doc(db, 'meta', metaDoc.id);
  const currentData = metaDoc.data() || {};

  // Add new city
  await updateDoc(metaRef, {
    cities: arrayUnion(cityName)
  });

  // Add micromarkets
  const currentMicromarkets = currentData.micromarkets || [];
  const newMicromarkets = micromarkets.map(micromarket => ({
    city: cityName,
    micromarket: micromarket
  }));

  await updateDoc(metaRef, {
    micromarkets: [...(Array.isArray(currentMicromarkets) ? currentMicromarkets : []), ...newMicromarkets]
  });
};

export const updateCity = async (oldCityName, newCityName, micromarkets) => {
  const metaDoc = await getMetaDocument();
  if (!metaDoc) throw new Error('Meta document not found');
  
  const metaRef = doc(db, 'meta', metaDoc.id);
  const currentData = metaDoc.data() || {};

  // Remove old city if name changed
  if (oldCityName !== newCityName) {
    await updateDoc(metaRef, {
      cities: arrayRemove(oldCityName)
    });
    await updateDoc(metaRef, {
      cities: arrayUnion(newCityName)
    });
  }

  // Update micromarkets
  const currentMicromarkets = currentData.micromarkets || [];
  const filteredMicromarkets = Array.isArray(currentMicromarkets)
    ? currentMicromarkets.filter(m => m.city !== oldCityName)
    : [];

  const newMicromarkets = micromarkets.map(micromarket => ({
    city: newCityName,
    micromarket: micromarket
  }));

  await updateDoc(metaRef, {
    micromarkets: [...filteredMicromarkets, ...newMicromarkets]
  });
};

export const deleteCity = async (cityName) => {
  const metaDoc = await getMetaDocument();
  if (!metaDoc) throw new Error('Meta document not found');
  
  const metaRef = doc(db, 'meta', metaDoc.id);
  const currentData = metaDoc.data() || {};

  // Remove city from cities array
  await updateDoc(metaRef, {
    cities: arrayRemove(cityName)
  });

  // Remove micromarkets for this city
  const currentMicromarkets = currentData.micromarkets || [];
  const updatedMicromarkets = Array.isArray(currentMicromarkets)
    ? currentMicromarkets.filter(m => m.city !== cityName)
    : [];

  await updateDoc(metaRef, {
    micromarkets: updatedMicromarkets
  });
};

// User Operations
export const getUserData = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      return { success: true, data: userDoc.data() };
    }
    return { success: false, error: 'User not found' };
  } catch (error) {
    console.error('Error fetching user data:', error);
    return { success: false, error: error.message };
  }
};

// Subscribe to user data changes
export const subscribeToUserData = (uid, onData, onError) => {
  const userRef = doc(db, 'users', uid);
  return onSnapshot(userRef, (doc) => {
    if (doc.exists()) {
      onData(doc.data());
    }
  }, onError);
};

export const subscribeToUsersCollection = (onData, onError) => {
  const usersCollection = collection(db, 'users');
  const q = query(usersCollection, limit(1)); // Assuming we want the first user for now
  
  return onSnapshot(q, (querySnapshot) => {
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      onData({
        id: userDoc.id,
        ...userDoc.data()
      });
    }
  }, onError);
};

export const subscribeToUserByEmail = (email, onData, onError) => {
  const usersCollection = collection(db, 'users');
  const q = query(usersCollection, where('email', '==', email), limit(1));
  
  return onSnapshot(q, (querySnapshot) => {
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      onData({
        id: userDoc.id,
        ...userDoc.data()
      });
    } else {
      console.log('No user found with email:', email);
      onError(new Error('User not found'));
    }
  }, onError);
};

// User Management Functions
export const createUser = async (userData) => {
  try {
    // Destructure user data
    const { 
      name, 
      email, 
      role, 
      password 
    } = userData;

    // Validate input
    if (!name || !email || !role || !password) {
      throw new Error('All fields are required');
    }

    // Check if user with email already exists
    const usersRef = collection(db, 'users');
    const existingUserQuery = query(usersRef, where('email', '==', email));
    const existingUserSnapshot = await getDocs(existingUserQuery);

    if (!existingUserSnapshot.empty) {
      throw new Error('User with this email already exists');
    }

    // Create user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(
      auth, 
      email, 
      password
    );

    // Add user to Firestore
    const newUserDoc = await addDoc(usersRef, {
      name,
      email,
      role,
     
    });

    return {
      success: true,
      userId: newUserDoc.id,
      message: 'User created successfully'
    };
  } catch (error) {
    console.error('Error creating user:', error);
    return {
      success: false,
      error: error.message || 'Error creating user'
    };
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const userRef = doc(db, 'users', userId);
    
    await updateDoc(userRef, userData);

    return {
      success: true,
      message: 'User updated successfully'
    };
  } catch (error) {
    console.error('Error updating user:', error);
    return {
      success: false,
      error: error.message || 'Error updating user'
    };
  }
};

export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    
    await deleteDoc(userRef);

    return {
      success: true,
      message: 'User deleted successfully'
    };
  } catch (error) {
    console.error('Error deleting user:', error);
    return {
      success: false,
      error: error.message || 'Error deleting user'
    };
  }
};
export const fetchUsers = async () => {
  try {
    const usersRef = collection(db, 'users');
    const usersSnapshot = await getDocs(usersRef);
    
    const users = usersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return {
      success: true,
      users
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      success: false,
      error: error.message || 'Error fetching users'
    };
  }
};

// Password Reset Functions
export const requestPasswordReset = async (email) => {
  try {
    // Check if user exists in Firestore
    const usersRef = collection(db, 'users');
    const userQuery = query(usersRef, where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);

    if (userSnapshot.empty) {
      return {
        success: false,
        error: 'No user found with this email address.'
      };
    }

    // Send password reset email
    await sendPasswordResetEmail(auth, email);

    return {
      success: true,
      message: 'Password reset email sent. Please check your inbox.'
    };
  } catch (error) {
    console.error('Password reset error:', error);
    
    // Map Firebase error codes to user-friendly messages
    const errorMessages = {
      'auth/invalid-email': 'Invalid email address.',
      'auth/user-not-found': 'No user found with this email address.',
      'auth/too-many-requests': 'Too many reset attempts. Please try again later.'
    };

    return {
      success: false,
      error: errorMessages[error.code] || 'Failed to send password reset email. Please try again.'
    };
  }
};

export const confirmNewPassword = async (oobCode, newPassword) => {
  try {
    // Confirm the password reset
    await confirmPasswordReset(auth, oobCode, newPassword);

    return {
      success: true,
      message: 'Password reset successful. You can now log in with your new password.'
    };
  } catch (error) {
    console.error('Password reset confirmation error:', error);
    
    // Map Firebase error codes to user-friendly messages
    const errorMessages = {
      'auth/invalid-action-code': 'Invalid or expired reset link.',
      'auth/weak-password': 'Password is too weak. Please choose a stronger password.'
    };

    return {
      success: false,
      error: errorMessages[error.code] || 'Failed to reset password. Please try again.'
    };
  }
};

export const updateProposalStatus = async (proposalId, newStatus) => {
  try {
    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      status: newStatus,
      updatedAt: serverTimestamp()
    });
    return { success: true };
  } catch (error) {
    console.error('Error updating proposal status:', error);
    return { success: false, error: error.message };
  }
};
