import React, { useEffect, useState } from "react";
import axios from "axios";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase/config";
import Screen from "./Pages/Public/Screen";
import Login from "./Pages//Public/login/Login";
import flyloader from "./assest/image/Fetching Proposal.gif";
import "./index.css";
import PrivateLogin from "./Pages/Private/login/PrivateLogin";
import Layout from "./Component/Private/Layout/Layout";
import Dashboard from "./Pages/Private/Dashboard/Dashboard";
import Proposals from "./Pages/Private/Dashboard/Proposals";
import EditProposal from "./Pages/Private/Dashboard/EditProposal";
import Centers from "./Pages/Private/Dashboard/Centers";
import Operators from "./Pages/Private/Dashboard/Operators";
import Inventories from "./Pages/Private/Dashboard/Inventories";
import Leads from "./Pages/Private/Dashboard/Leads";
import ActionItems from './Pages/Private/Dashboard/ActionItems';
import Settings from './Pages/Private/Dashboard/Settings';
import Search from './Pages/Private/Dashboard/Search';
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { getProposalStatus } from "./Services/FirebaseUtils";
import OperatorCenters from './Component/Public/OperatorCenters';
import CenterInventories from './Component/Public/CenterInventories';
import InventoryDetails from "./Component/Public/InventoryDetails";

const App = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  // Check for private email-based authentication
  const isPrivateAuthenticated = localStorage.getItem('privateEmail') !== null;
  
  // Check for public authToken-based authentication
  const isPublicAuthenticated = localStorage.getItem('authToken') !== null;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Navigate to="/private/login" replace />} />
        
        {/* Handle /private route */}
        <Route path="/private" element={<Navigate to="/private/login" replace />} />
        
        {/* Public Login Routes */}
        <Route path="/public/published-proposal/login" element={<Login proposalId={proposalId} />} />

        {/* Private Login Routes */}
        <Route 
          path="/private/login" 
          element={
            isPrivateAuthenticated ? 
              <Navigate to="/private/dashboard" replace /> 
              : <PrivateLogin />
          } 
        />

        {/* Private Dashboard Routes */}
        <Route 
          path="/private/dashboard" 
          element={
            isPrivateAuthenticated ? 
              <Layout /> 
              : <Navigate to="/private/login" replace />
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="proposals" element={<Proposals proposalId={proposalId}/>} />
          <Route path="proposals/new" element={<EditProposal />} />
          <Route path="proposals/edit/:id" element={<EditProposal />} />
          <Route path="centers" element={<Centers />} />
          <Route path="operators" element={<Operators />} />
          <Route path="inventory" element={<Inventories />} />
          <Route path="leads" element={<Leads />} />
          <Route path="action-items" element={<ActionItems />} />
          <Route path="settings" element={<Settings />} />
          <Route path="search" element={<Search />} />
        </Route>

        {/* Existing Proposal Routes */}
        <Route
          path="/public/published-proposal"
          element={
            isPublicAuthenticated ? (
              <ProposalFetcher proposalId={proposalId} />
            ) : (
              <Navigate 
                to={`/public/published-proposal/login/?proposalId=${proposalId}`} 
                replace 
              />
            )
          }
        />
        <Route path="/public/operator/:operatorId" element={<OperatorCenters />} />
        <Route path="/public/operator/:operatorId/:centerId" element={<CenterInventories />} />
        <Route path="/public/operator/:operatorId/:centerId/:inventoryId" element={<InventoryDetails />}/>
      </Routes>
    </ThemeProvider>
  );
}

const ProposalFetcher = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shortlistedCenters, setShortlistedCenters] = useState({});
  const [schedules, setSchedules] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  useEffect(() => {
    const fetchProposalData = async () => {
      try {
        const authToken = window.localStorage.getItem('authToken');

        if (!authToken) {
          navigate('/?proposalId=' + proposalId);
          return;
        }

        // First check if the proposal is published
        const statusResult = await getProposalStatus(proposalId);
        if (!statusResult.success) {
          setError(statusResult.error);
          setLoading(false);
          return;
        }

        if (!statusResult.isPublished) {
          setError('This proposal is not available for viewing');
          setLoading(false);
          return;
        }

        // Check if proposal exists in Firestore
        const proposalRef = doc(db, 'proposals', proposalId);
        const proposalDoc = await getDoc(proposalRef);

        let proposalData;
        let shouldFetchFromAPI = false;

        if (!proposalDoc.exists()) {
          // Proposal not in database, must fetch from API
          shouldFetchFromAPI = true;
        } else {
          // Check if proposedOptions is empty or missing
          const existingProposalData = proposalDoc.data();
          if (!existingProposalData.proposedOptions || existingProposalData.proposedOptions.length === 0) {
            shouldFetchFromAPI = true;
          } else {
            proposalData = existingProposalData;
          }
        }

        // Fetch from API if needed
        if (shouldFetchFromAPI) {
          const baseUrl = 'https://gofloaters.firebaseapp.com';
          const response = await axios.get(`${baseUrl}/proposal/${proposalId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            }
          });

          if (response.data && response.data.data) {
            proposalData = response.data.data;

            // Store API response in Firestore
            await setDoc(proposalRef, {
              clientName: proposalData.clientName || "Unknown Client",
              requirements: proposalData.requirements || "No requirements specified",
              proposedOptions: proposalData.proposedOptions || [],
              refreshData: !proposalData.proposedOptions || proposalData.proposedOptions.length === 0,
              lastUpdated: new Date().toISOString(),
              status: 'Published' // Ensure status is set to Published
            }, { merge: true });
          } else {
            throw new Error('Invalid response format from server');
          }
        }

        // Ensure proposalData is set
        if (!proposalData) {
          throw new Error('No proposal data available');
        }

        setProposal(proposalData);
      } catch (error) {
        console.error('Error fetching proposal:', error);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            // Authentication error - redirect to login
            navigate('/?proposalId=' + proposalId);
            return;
          } else if (error.response.status === 500) {
            setError('Server error. Please try again later.');
          } else if (error.response.status === 404) {
            setError('Proposal not found. Please check the proposal ID.');
          } else {
            setError(error.response.data?.message || 'Error fetching proposal data');
          }
        } else if (error.request) {
          setError('No response from server. Please check your internet connection.');
        } else {
          setError('Error fetching proposal data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (proposalId) {
      fetchProposalData();

      // Add onSnapshot listener for proposal and shortlist updates
      const proposalRef = doc(db, 'proposals', proposalId);
      const unsubscribe = onSnapshot(proposalRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();

          // Check if the proposal is still published
          if (data.status !== 'Published') {
            setError('This proposal is no longer available for viewing');
            setProposal(null);
            return;
          }

          // Update proposal data if it exists
          if (data.proposedOptions) {
            setProposal(prevProposal => ({
              ...prevProposal,
              ...data
            }));
          }

          // Update shortlisted centers
          const shortlistData = data.shortlist || [];
          const shortlistMap = shortlistData.reduce((acc, item) => {
            if (item && item.inventoryId) {
              acc[item.inventoryId] = item.shortlistedBy || [];
            }
            return acc;
          }, {});
          setShortlistedCenters(shortlistMap);

          // Update visit schedules
          setSchedules(Array.isArray(data.visitSchedule) ? data.visitSchedule : []);
        }
      }, (error) => {
        console.error('Error listening to proposal changes:', error);
      });

      return () => unsubscribe();
    } else {
      setError('No proposal ID provided');
      setLoading(false);
    }
  }, [proposalId, navigate]);

  if (loading) return (
    <div className='loader-head'>
      <img className='loadernew' src={flyloader} alt="Loading..." />
    </div>
  );

  if (error) return (
    <div style={{ textAlign: 'center', marginTop: '250px' }}>
      <p style={{ fontSize: 20, fontWeight: 600, color: '#dc3545' }}>{error}</p>
      <p style={{ fontSize: 16, color: '#666', marginTop: '1rem' }}>
        If the problem persists, please contact support.
      </p>
    </div>
  );

  if (!proposal) return (
    <div style={{ textAlign: 'center', marginTop: '250px' }}>
      <p style={{ fontSize: 20, fontWeight: 600, color: '#666' }}>
        Unable to retrieve proposal details. Please try again later.
      </p>
    </div>
  );

  return <Screen
    proposal={proposal}
    proposalId={proposalId}
    shortlistedCenters={shortlistedCenters}
    schedules={schedules}
  />;
};

export default App;
