import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase/config';
import {  addToProposal,
    fetchCenterById,
    fetchOperatorById} from './FirebaseUtils';

export const searchInventory = async (searchData) => {
    try {
        console.log("searchData", searchData);
        // Get current user
        const user = auth.currentUser;
        if (!user) {
            throw new Error('User not authenticated');
        }

        // Create base query
        let q = collection(db, 'inventories');
        const constraints = [];

        // Add filters based on search criteria
        if (searchData.city) {
            constraints.push(where('city', '==', searchData.city));
        }

        if (searchData.micromarket) {
            constraints.push(where('micromarket', '==', searchData.micromarket));
        }

        // Add size constraints if seats are specified
        if (searchData.seatsNeeded) {
            const minSeats = Math.floor(Number(searchData.seatsNeeded) * 0.75);
            const maxSeats = Math.ceil(Number(searchData.seatsNeeded) * 1.25);
            constraints.push(where('size', '>=', minSeats));
            constraints.push(where('size', '<=', maxSeats));
        }

        // Get all matching inventory documents
        const querySnapshot = await getDocs(query(q, ...constraints));
        let inventories = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        // console.log("inventories", inventories);

        // Fetch center data for each inventory
        const results = await Promise.all(
            inventories.map(async (inventory) => {
                try {
                    // Initialize cache if not exists
                    if (!searchInventory.centerCache) {
                        searchInventory.centerCache = new Map();
                    }
                    if (!searchInventory.operatorCache) {
                        searchInventory.operatorCache = new Map();
                    }

                    // Try to get center from cache first
                    let centerData;
                    let centerDoc;
                    if (searchInventory.centerCache.has(inventory.centerId)) {
                        const cachedCenter = searchInventory.centerCache.get(inventory.centerId);
                        centerData = cachedCenter.data;
                        centerDoc = cachedCenter.doc;
                    } else {
                        // Fetch and cache center data if not in cache
                        centerDoc = await getDoc(doc(db, 'centers', inventory.centerId));
                        if (!centerDoc.exists()) {
                            console.warn(`Center not found for inventory ${inventory.id}`);
                            return null;
                        }
                        centerData = centerDoc.data();
                        searchInventory.centerCache.set(inventory.centerId, { doc: centerDoc, data: centerData });
                    }
                    
                    // Try to get operator from cache first
                    let operatorData = {};
                    if (centerData.operatorId) {
                        if (searchInventory.operatorCache.has(centerData.operatorId)) {
                            operatorData = searchInventory.operatorCache.get(centerData.operatorId);
                        } else {
                            // Fetch and cache operator data if not in cache
                            const operatorDoc = await getDoc(doc(db, 'operators', centerData.operatorId));
                            if (operatorDoc.exists()) {
                                operatorData = operatorDoc.data();
                                searchInventory.operatorCache.set(centerData.operatorId, operatorData);
                            } else {
                                console.warn(`Operator not found for center ${inventory.centerId}`);
                            }
                        }
                    }

                    const listPrice = Number(centerData.listPrice);

                    // Check budget constraints
                    if (searchData.budgetFrom && listPrice < Number(searchData.budgetFrom)) {
                        console.log("budgetFrom not matching", inventory.id);
                        return null;
                    }
                    if (searchData.budgetTo && listPrice > Number(searchData.budgetTo)) {
                        console.log("budgetTo not matching", inventory.id);
                        return null;
                    }

                    // Return combined data
                    return {
                        ...inventory,
                        center: {
                            id: centerDoc.id,
                            ...centerData
                        },
                        operator: {
                            id: centerData.operatorId,
                            ...operatorData
                        }
                    };
                } catch (error) {
                    console.error(`Error fetching center for inventory ${inventory.id}:`, error);
                    return null;
                }
            })
        );

        // Filter out null results and sort by list price
        const validResults = results
            .filter(result => result !== null)
            .sort((a, b) => Number(a.center.listPrice) - Number(b.center.listPrice))
            .map(result => transformInventoryToOption(result, result.center, result.operator));

        console.log("validResults", validResults);
        return {
            success: true,
            data: validResults
        };
    } catch (error) {
        console.error('Error searching inventory:', error);
        return {
            success: false,
            error: error.message || 'Error searching inventory'
        };
    }
};

export const transformInventoryToOption = (inventory) => {
    try {
        console.log('Transforming Inventory:', JSON.stringify(inventory, null, 2));

        const centerId = inventory.centerId;
        const operatorId = inventory.operatorId;

        // Transform inventory photos
        const inventoryPhotos = (inventory.photos || []).map(photo => {
            const { name, type, description, url } = photo;
            return {
                url,
                label: name ? name : type,
                description: description ? description : ''
            };
        });

        // Transform center photos
        const centerPhotos = (inventory.center?.photos || []).map(photo => {
            const { type, name, description, url } = photo;
            return {
                url,
                label: name ? name : type,
                description: description ? description : ''
            };
        });

        // Create and return the option object
        const transformedInventory = {
            id: inventory.id || "",
            amenities: inventory.center?.amenities || [],
            annualEscalation: inventory.operator.annualEscalation || 0,
            availabilityDate: inventory.availabilityDate || "",
            availabileFrom: inventory.availabilityDate || "",
            bikeParkingCost: inventory.center?.bikeParkingCost || 0,
            billableSeats: inventory.size || 0,
            cabinNumber: inventory.cabinNumber || "",
            canSupport247: inventory.center?.canSupport247 || "No",
            carParkingCost: inventory.center?.carParkingCost || 0,
            centerDescription: inventory.center?.description || "",
            centerName: inventory.centerName || "",
            city: inventory.city || "",
            complimentaryBikeParkSlots: inventory.center?.complimentaryBikeParkSlots || 0,
            complimentaryCarParkSlots: inventory.center?.complimentaryCarParkSlots || 0,
            complimentaryMeetingRoomCredits: inventory.center?.complimentaryMeetingRoomCredits || 0,
            customizationCost: inventory.customizationCost || 0,
            floor: inventory.floor || "",
            inventory: inventory.inventoryName || "",
            inventoryDescription: inventory.description || "",
            inventoryType: inventory.inventoryType || "",
            locality: inventory.locality || "",
            location: {
                lat: inventory.center?.latitude || 0,
                landmark: inventory.center?.landmark || "",
                lng: inventory.center?.longitude || 0,
                googleMapsLocation: inventory.center?.googleMapsLocation || "",
                address: inventory.center?.address || ""
            },
            photos: [...inventoryPhotos, ...centerPhotos],
            virtualTours: inventory.center?.virtualTours || [],
            pricePerSeat: inventory.center?.listPrice || 0,
            securityDeposit: (inventory.center?.listPrice * inventory.size * (inventory.operator.deposit || 0)) || 0,
            securityDepositMonths: inventory.operator.deposit || 0,
            totalRentPerMont: (inventory.center?.listPrice * inventory.size) || 0,
            operatorName: inventory.operator.operatorName || "",
            microMarket: inventory.center?.micromarket || "",
        };

        console.log('Transformed Inventory:', JSON.stringify(transformedInventory, null, 2));

        return transformedInventory;
    } catch (error) {
        console.error('Error transforming inventory data:', error);
        throw error;
    }
};
