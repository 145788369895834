import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Toolbar,
  FormHelperText,
  Alert,
  Snackbar,
  Card,
  Chip,
  InputAdornment,
  useTheme,
  Grid
} from '@mui/material';
import {
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addNewLead, updateExistingLead, createNewProposal, generateLeadProfile } from '../../../redux/slices/leadsSlice';
import { generateProfile } from '../../../Services/GeminiUtils';
import { useNavigate } from 'react-router-dom';
import LeadForm from '../../../Components/Private/Forms/LeadForm';
import LeadProfile from '../../../Components/Private/LeadProfile';
import useMeta from '../../../hooks/useMeta';

// Mock data - Replace with actual data from your backend
// const mockLeads = [
//   {
//     id: 1,
//     companyName: 'Tech Corp',
//     status: 'Open',
//     category: 'Hot',
//     source: 'Digital Marketing',
//     contactName: 'John Doe',
//     contactPhone: '+91 9876543210',
//     contactEmail: 'john@techcorp.com',
//     lastActionDate: '2024-01-10',
//     nextActionDate: '2024-01-15',
//     city: 'Bangalore',
//   },
//   // Add more mock data as needed
// ];

const statusOptions = ['Open', 'Closed', 'Lost'];
const categoryOptions = ['Hot', 'Warm', 'Cold'];
const sourceOptions = ['Existing Client', 'Digital Marketing', 'Inbound', 'Referral', 'Other'];

const Leads = () => {
  const dispatch = useDispatch();
  const { items: leads, loading } = useSelector((state) => state.leads);
  const navigate = useNavigate();
  const theme = useTheme();
  const {cities} = useMeta();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editLead, setEditLead] = useState(null);
  const [isGeneratingProfile, setIsGeneratingProfile] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [generatedProfile, setGeneratedProfile] = useState({});

  // New lead form state
  const [newLead, setNewLead] = useState({
    companyName: '',
    companyWebsite: '',
    status: '',
    category: '',
    source: '',
    contactName: '',
    designation: '',
    contactPhone: '',
    contactEmail: '',
    contactLinkedIn: '',
    city: '',
    lastActionDate: '',
    nextActionDate: '',
  });

  // Add form validation state
  const [formErrors, setFormErrors] = useState({});

  const validateForm = (lead = newLead) => {
    const errors = {};
    const requiredFields = ['companyName', 'status', 'category', 'source', 'contactName', 'city'];

    requiredFields.forEach(field => {
      if (!lead[field]) {
        errors[field] = 'This field is required';
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setPage(0);
  };

  const handleAddDialogOpen = () => {
    setOpenAddDialog(true);
  };

  const handleAddDialogClose = () => {
    setOpenAddDialog(false);
    setNewLead({
      companyName: '',
      companyWebsite: '',
      status: '',
      category: '',
      source: '',
      contactName: '',
      designation: '',
      contactPhone: '',
      contactEmail: '',
      contactLinkedIn: '',
      city: '',
      lastActionDate: '',
      nextActionDate: '',
    });
  };

  const handleNewLeadChange = (field) => (event) => {
    setNewLead({
      ...newLead,
      [field]: event.target.value,
    });
  };

  const handleAddLead = async (formData) => {
    try {
      setIsSaving(true);
      const result = await dispatch(addNewLead(formData)).unwrap();
      setOpenAddDialog(false);
      setSnackbar({
        open: true,
        message: 'Lead added successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error adding lead: ' + error.message,
        severity: 'error'
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleMenuOpen = (event, lead) => {
    setAnchorEl(event.currentTarget);
    setSelectedLead(lead);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLead(null);
  };

  const handleEditDialogOpen = (lead) => {
    setEditLead({
      ...lead,
      companyWebsite: lead.companyWebsite || '',
      contactLinkedIn: lead.contactLinkedIn || '',
      lastActionDate: lead.lastActionDate || '',
      nextActionDate: lead.nextActionDate || '',
    });
    setOpenEditDialog(true);
    handleMenuClose();
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditLead(null);
    setFormErrors({});
  };

  const handleEditChange = (field) => (event) => {
    setEditLead({
      ...editLead,
      [field]: event.target.value,
    });
  };

  const handleViewDialogOpen = (lead) => {
    setSelectedLead(lead);
    setOpenViewDialog(true);
    handleMenuClose();
  };

  const handleViewDialogClose = () => {
    setOpenViewDialog(false);
    setSelectedLead(null);
  };

  const handleUpdateLead = async (id, data) => {
    try {
      setIsSaving(true);
      await dispatch(updateExistingLead({ id, data })).unwrap();
      setOpenEditDialog(false);
      setSnackbar({
        open: true,
        message: 'Lead updated successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error updating lead: ' + error.message,
        severity: 'error'
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleCreateProposal = async (lead) => {
    try {
      handleMenuClose();
      const result = await dispatch(createNewProposal(lead.companyName)).unwrap();
      if (result) {
        navigate(`/private/dashboard/proposals/edit/${result.proposalId}`, { state: { mode: 'edit' } });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error creating proposal: ' + error.message,
        severity: 'error'
      });
    }
  };

  const generateCompanyProfile = async (lead) => {
    const prompt = `You are a professional business analyst tasked with creating a detailed company profile. Research and analyze "${lead.companyName}" using their official website (${lead.companyWebsite}) as your primary source.

Research Guidelines:
1. MOST IMPORTANT: Visit and thoroughly analyze their official website (${lead.companyWebsite})
2. Focus on factual, current information directly from the company's website
3. For any information not found on the website, use only these trusted sources in order of preference:
   - Company's official LinkedIn page
   - Official press releases
   - Company's official social media
   - Bloomberg or Reuters company profiles
   - Crunchbase or similar verified business databases
4. Do not include any information that cannot be verified from these sources
5. Clearly indicate the source of each piece of information

Generate a detailed company profile in markdown format with the following sections:

# Company Profile: ${lead.companyName}

## Industry & Market Position
[Describe the primary industry sector, any secondary sectors, and the company's position in these markets]

## Company Size & Scale
[Provide employee count, company scale, and any relevant size metrics]

## Core Business
- Main Business Focus: [Core business description]
- Products/Services: [List key products/services]
- Target Market: [Describe target customer segments]

## Company History
- Founded: [Year and location]
- Founders: [List key founders and their roles]
- Key Milestones: [Important company milestones]

## Office Locations
- Headquarters: [HQ location]
- Other Locations: [List other significant office locations]

## Market Overview
- Market Position: [Company's current market position]
- Unique Value Proposition: [What sets them apart]
- Key Achievements: [Notable achievements, awards]

## Recent Developments
[List any significant recent news or developments from the last 3 months]

---
Sources: [List all sources used for this profile]

Return ONLY the markdown formatted text, no additional formatting or explanation.`;

    try {
      const companyProfile = await generateProfile(prompt);
      return { success:true,profile: companyProfile, prompt };
    } catch (error) {
      console.error('Error generating company profile:', error);
      throw error;
    }
  };

  const generateContactProfile = async (lead) => {
    const prompt = `Create a profile of ${lead.contactName} who works for ${lead.companyName} with designation ${lead.designation} and generate a professional profile. Focus on their current role, experience, and decision-making capabilities.

# ${lead.contactName}

## Current Position
- Role and company
- Key responsibilities
- Duration in current role

## Professional Experience
- Previous roles and companies
- Notable achievements
- Areas of expertise
- Industry experience

## Decision Making Authority
- Level of seniority
- Scope of influence
- Budget authority indicators
- Team size (if applicable)

## Education & Certifications
- Academic background
- Professional certifications
- Relevant training

## Key Skills & Expertise
- Technical skills
- Management skills
- Industry-specific knowledge
- Notable competencies

---
Sources: [List all sources used for this profile]

Please ensure all information is factual and directly derived from credible sources to provide information. If certain information is not available, skip that section rather than making assumptions.

Format the response in clean markdown with appropriate headings and bullet points.`;

    try {
      const contactProfile = await generateProfile(prompt);
      return { success: true, profile: contactProfile, prompt };
    } catch (error) {
      console.error('Error generating contact profile:', error);
      throw error;
    }
  };

  const handleGenerateProfile = async (lead) => {
    try {
      setIsGeneratingProfile(true);
      setOpenProfileDialog(true);
      handleMenuClose();

      const [companyResult, contactResult] = await Promise.all([
        generateCompanyProfile(lead),
        generateContactProfile(lead)
      ]);

      if (!companyResult.success || !contactResult.success) {
        throw new Error('Failed to generate profiles');
      }

      const profileData = {
        company: companyResult.profile,
        contact: contactResult.profile,
        prompts: {
          companyPrompt: companyResult.prompt,
          contactPrompt: contactResult.prompt
        }
      };

      await dispatch(generateLeadProfile({ leadId: lead.id, profile: profileData })).unwrap();
      setGeneratedProfile(profileData);
      setSnackbar({
        open: true,
        message: 'Profile generated successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error generating profile:', error);
      setSnackbar({
        open: true,
        message: 'Error generating profile: ' + error.message,
        severity: 'error'
      });
      setOpenProfileDialog(false);
    } finally {
      setIsGeneratingProfile(false);
    }
  };

  const handleProfileDialogClose = () => {
    setOpenProfileDialog(false);
    setGeneratedProfile({});
  };

  // Filter leads based on search and filters
  const filteredLeads = leads.filter((lead) => {
    const matchesSearch = lead.companyName.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCity = !selectedCity || lead.city === selectedCity;
    const matchesAssignee = !assigneeFilter || lead.assignedTo === assigneeFilter;
    return matchesSearch && matchesCity && matchesAssignee;
  });

  // Get current page's leads
  const currentLeads = filteredLeads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'open':
        return {
          color: theme.palette.success.dark,
          backgroundColor: theme.palette.success.light,
        };
      case 'closed':
        return {
          color: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
        };
      case 'lost':
        return {
          color: theme.palette.error.dark,
          backgroundColor: theme.palette.error.light,
        };
      default:
        return {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[100],
        };
    }
  };

  const getCategoryColor = (category) => {
    switch (category?.toLowerCase()) {
      case 'hot':
        return {
          color: theme.palette.error.dark,
          backgroundColor: theme.palette.error.light,
        };
      case 'warm':
        return {
          color: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
        };
      case 'cold':
        return {
          color: theme.palette.info.dark,
          backgroundColor: theme.palette.info.light,
        };
      default:
        return {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[100],
        };
    }
  };

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>
        <Toolbar disableGutters>
          <Box sx={{ flex: 1 }}>
            <TextField
              size="small"
              placeholder="Search leads..."
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 300 }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddDialogOpen}
              sx={{ ml: 1 }}
            >
              Add Lead
            </Button>
          </Box>
        </Toolbar>
      </Box>
      {/* Separator Line */}
      <Box sx={{
        height: "1px",
        backgroundColor: "#e0e0e0",
        width: "calc(100% + 32px)",
        mx: -2,
        mb: 2
      }} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>Company Name</TableCell>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>Status</TableCell>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>Category</TableCell>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>Contact</TableCell>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>City</TableCell>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>Last Action</TableCell>
              <TableCell sx={{
                fontWeight: 500,
                color: "#6B7280",
                borderBottom: "1px solid #E5E7EB",
                py: 1.5
              }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLeads
              .map((lead) => (
                <TableRow
                  key={lead.id}
                  hover
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <Typography variant="body1">{lead.companyName}</Typography>
                  </TableCell>
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <Chip
                      label={lead.status}
                      size="small"
                      sx={getStatusColor(lead.status)}
                    />
                  </TableCell>
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <Chip
                      label={lead.category}
                      size="small"
                      sx={getCategoryColor(lead.category)}
                    />
                  </TableCell>
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <Typography variant="body2">{lead.contactName}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {lead.contactEmail}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <Typography variant="body2">{lead.city}</Typography>
                  </TableCell>
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <Typography variant="body2">{new Date(lead.lastActionDate).toLocaleDateString()}</Typography>
                  </TableCell>
                  <TableCell sx={{
                    borderBottom: "1px solid #E5E7EB",
                    color: "#111827",
                    py: 1.5
                  }}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMenuOpen(e, lead);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredLeads.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Add Lead Dialog */}
      <Dialog open={openAddDialog} onClose={handleAddDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>Add New Lead</DialogTitle>
        <DialogContent>
          <LeadForm
            lead={newLead}
            handleChange={handleNewLeadChange}
            formErrors={formErrors}
            statusOptions={statusOptions}
            categoryOptions={categoryOptions}
            sourceOptions={sourceOptions}
            cityOptions={cities}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose}>Cancel</Button>
          <Button onClick={() => handleAddLead(newLead)} variant="contained">Add Lead</Button>
        </DialogActions>
      </Dialog>

      {/* Actions Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleEditDialogOpen(selectedLead)}>Edit</MenuItem>
        <MenuItem onClick={() => handleViewDialogOpen(selectedLead)}>View</MenuItem>
        <MenuItem onClick={() => handleCreateProposal(selectedLead)}>Create Proposal</MenuItem>
        {selectedLead?.companyWebsite && selectedLead?.contactLinkedIn && (
          <MenuItem onClick={() => handleGenerateProfile(selectedLead)}>Generate Profile</MenuItem>
        )}
      </Menu>

      {/* View Details Dialog */}
      <Dialog
        open={openViewDialog}
        onClose={handleViewDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Lead Details</DialogTitle>
        <DialogContent>
          {selectedLead && (
            <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)', pt: 2 }}>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Company Name</Typography>
                <Typography variant="body1">{selectedLead.companyName}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                <Typography variant="body1">{selectedLead.status}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Category</Typography>
                <Typography variant="body1">{selectedLead.category}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Source</Typography>
                <Typography variant="body1">{selectedLead.source}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Contact Name</Typography>
                <Typography variant="body1">{selectedLead.contactName}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Contact Phone</Typography>
                <Typography variant="body1">{selectedLead.contactPhone}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Contact Email</Typography>
                <Typography variant="body1">{selectedLead.contactEmail}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">City</Typography>
                <Typography variant="body1">{selectedLead.city}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Last Action Date</Typography>
                <Typography variant="body1">{selectedLead.lastActionDate}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Next Action Date</Typography>
                <Typography variant="body1">{selectedLead.nextActionDate}</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Lead Dialog */}
      <Dialog open={openEditDialog} onClose={handleEditDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Lead</DialogTitle>
        <DialogContent>
          {editLead && (
            <LeadForm
              lead={editLead}
              handleChange={handleEditChange}
              formErrors={formErrors}
              statusOptions={statusOptions}
              categoryOptions={categoryOptions}
              sourceOptions={sourceOptions}
              cityOptions={cities}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={() => handleUpdateLead(editLead.id, editLead)} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Profile Dialog */}
      <LeadProfile
        open={openProfileDialog}
        onClose={handleProfileDialogClose}
        profile={generatedProfile}
        loading={isGeneratingProfile}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default Leads;
