import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';
import InventoryForm from '../../Components/Private/InventoryForm';
import CenterForm from '../../Components/Private/CenterForm';
import { v4 as uuidv4 } from 'uuid';
import { fetchInventories, updateInventory, fetchCenters } from '../../Services/FirebaseUtils';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc, setDoc, collection, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import useMeta from '../../hooks/useMeta';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const auth = getAuth();
const storage = getStorage();

const ImportInventoryModal = ({
  open,
  onClose,
  onImport,
  onEditItem,
  operatorName,
  centerInventoryData = {},
  operatorId,
  theme,
  onDataRefresh
}) => {
  // Validate and normalize centerInventoryData
  const centers = Array.isArray(centerInventoryData?.centers)
    ? centerInventoryData.centers
    : [];
  const existingInventories = Array.isArray(centerInventoryData?.existingInventories)
    ? centerInventoryData.existingInventories
    : [];

    
  const { cities } = useMeta()
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [importData, setImportData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [centerMappings, setCenterMappings] = useState({});
  const [inventoryMappings, setInventoryMappings] = useState({});
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInventoryFormOpen, setIsInventoryFormOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [showInventoryForm, setShowInventoryForm] = useState(false);
  const [showCenterForm, setShowCenterForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newCenterData, setNewCenterData] = useState(null);
  const [error, setError] = useState(null);
  const [extractedInventories, setExtractedInventories] = useState([]);
  const transformComponentRef = useRef(null);
  const [center, setCenter] = useState([]);
  const [centerEditModeIndex, setCenterEditModeIndex] = useState(null);
  const [inventoryEditModeIndex, setInventoryEditModeIndex] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);
  console.log(operatorName, 'selectedCity');
  const formatDate = (dateStr) => {
    if (!dateStr) return new Date().toISOString().split('T')[0];

    // If it's already in YYYY-MM-DD format, return as is
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
      return dateStr;
    }

    // Handle DD/MM/YYYY format
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
      const [day, month, year] = dateStr.split('/');
      return `${year}-${month}-${day}`;
    }

    try {
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateStr);
        return new Date().toISOString().split('T')[0];
      }
      return date.toISOString().split('T')[0];
    } catch (e) {
      console.error('Error parsing date:', dateStr, e);
      return new Date().toISOString().split('T')[0];
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please select an image file');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = (e) => {
        setPreviewImage(e.target.result);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImport = async () => {
    if (!selectedFile || !selectedCity) {
      setError('Please select all required fields');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      await processImageWithGemini(previewImage);
    } catch (error) {
      console.error('Error during import:', error);
      setError(error.message || 'An error occurred during import');
    } finally {
      setIsLoading(false);
    }
  };

  const processImageWithGemini = async (imageData) => {
    try {
      setIsLoading(true);
      setError(null);

      // Get current user's ID token
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated. Please log in.');
      }

      const idToken = await user.getIdToken();

      const importFunction = httpsCallable(functions, 'importInventoryFromImage');

      const result = await importFunction({
        operatorName,
        cityName: selectedCity,
        imageData: imageData
      });

      if (result.data.success) {
        // Add unique IDs to each item
        const dataWithIds = result.data.data.map(item => ({
          ...item,
          id: uuidv4()
        }));
        setImportData(dataWithIds);
        setEditedData(dataWithIds);
        setExtractedData(dataWithIds);
        setError(null);
      } else {
        setError('Failed to process image. Please try again.');
        console.error('Error processing image:', result.data.error);
      }
    } catch (error) {
      console.error('Error calling import function:', error);
      setError(error.message || 'An error occurred while processing the image.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (item) => {
    if (editingItemId === item.id) {
      // Save logic here
      handleInventoryFormSubmit(item);
      setEditingItemId(null);
      setSelectedItem(null);
      setShowInventoryForm(false);
    } else {
      setEditingItemId(item.id);
      setSelectedItem(item);
      setShowInventoryForm(true);
    }
  };

  const handleInventoryFormClose = () => {
    setIsInventoryFormOpen(false);
    setEditingItem(null);
  };

  const handleInventoryFormSubmit = async (formData) => {
    try {

      // Ensure we have the original item's ID
      const inventoryId = editingItem?.id || editingItem?.inventoryId || formData?.inventoryId;

      if (!inventoryId) {
        // Generate a new ID if none exists
        const newId = uuidv4();

        // Keep existing data and update with form fields
        const inventoryData = removeUndefinedFields({
          ...editingItem,
          ...formData,
          inventoryId: newId,
          id: newId,
          availabilityDate: formatDate(formData.availabilityDate),
          operatorName,
          active: true,
          updatedAt: new Date().toISOString()
        });

        const updatedEditedData = editedData.map(item =>
          item === editingItem ? { ...inventoryData, extractedData: editingItem } : item
        );
        setEditedData(updatedEditedData);

        await updateInventory(newId, inventoryData);
      } else {
        // Update existing inventory
        const inventoryData = removeUndefinedFields({
          ...editingItem,
          ...formData,
          inventoryId,
          id: inventoryId,
          availabilityDate: formatDate(formData.availabilityDate),
          operatorName,
          active: true,
          updatedAt: new Date().toISOString()
        });



        const updatedEditedData = editedData.map(item =>
          item === editingItem ? { ...inventoryData, extractedData: editingItem } : item
        );
        setEditedData(updatedEditedData);

        await updateInventory(inventoryId, inventoryData);
      }

      // Refresh the data
      await fetchInventories();

      // Close form and clear editing state
      setIsInventoryFormOpen(false);
      setEditingItem(null);

    } catch (error) {
      console.error('Error updating inventory:', error);
    }
  };

  const handleAddClick = (item) => {
    if (!operatorId) {
      setError('Please select an operator first');
      return;
    }

    setSelectedItem(item);
    // Defensive check for centerInventoryData
    const centers = centerInventoryData?.centers || [];
    const existingInventories = centerInventoryData?.existingInventories || [];

    // Safely check if center exists
    const centerExists = Array.isArray(centers) && centers.some(
      center => center.centerName?.toLowerCase().trim() === item.centerName?.toLowerCase().trim()
    );

    // Check if inventory exists
    const matchingInventory = existingInventories.find(inv =>
      inv.inventoryName?.toLowerCase().trim() === item.inventoryName?.toLowerCase().trim() &&
      String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
    );

    setSelectedItem(item);
    // Determine which form to show
    if (!centerExists) {
      setShowCenterForm(true);
    } else if (!matchingInventory) {
      setShowInventoryForm(true);
    } else {
      setEditingItem(matchingInventory);
      setShowInventoryForm(true);
    }
  };

  const handleCenterFormSubmit = async (formData) => {
    try {
      setIsLoading(true);
      setError(null);

      if (!operatorId) {
        throw new Error('Please select an operator first');
      }

      // Generate a new ID for the center
      const centerId = uuidv4();

      // Prepare center data with required fields
      const centerData = removeUndefinedFields({
        ...formData,
        id: centerId,
        operatorId: operatorId,
        operatorName: operatorName || '',
        centerName: formData.centerName || '',
        city: formData.city || '',
        micromarket: formData.micromarket || '',
        locality: formData.locality || '',
        landmark: formData.landmark || '',
        centerManagerName: formData.centerManagerName || '',
        centerManagerPhone: formData.centerManagerPhone || '',
        centerManagerEmail: formData.centerManagerEmail || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      console.log('Saving center data:', centerData);

      // Save center to Firestore
      const centerRef = doc(db, 'centers', centerId);
      await setDoc(centerRef, centerData);

      // Set new center data for subsequent inventory creation
      setNewCenterData(centerData);
      setShowCenterForm(false);
      setShowInventoryForm(true);

      // Optional: Trigger data refresh in parent component
      if (onDataRefresh) {
        onDataRefresh();
      }

      return centerData;
    } catch (error) {
      console.error('Error saving center:', error);
      setError(error.message || 'Failed to save center');
      setIsLoading(false);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleInventoryFormSubmitAdd = async (formData) => {
    try {
      setIsLoading(true);
      setError(null);

      // Ensure we have a center (either existing or newly created)
      const centerId = newCenterData?.id || formData.centerId;
      const centerName = newCenterData?.centerName || formData.centerName;

      if (!centerId) {
        throw new Error('Center information is required');
      }

      // Prepare inventory data
      const inventoryData = removeUndefinedFields({
        ...formData,
        id: uuidv4(),
        operatorId: operatorId,
        operatorName: operatorName,
        centerId: centerId,
        centerName: centerName,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        // Explicitly handle optional fields
        address: formData.address || '',
        size: formData.size || '',
        floor: formData.floor || '',
        inventoryType: formData.inventoryType || '',
        availabilityDate: formData.availabilityDate || '',
        description: formData.description || '',
        quantity: formData.quantity || '1',
        city: formData.city || '',
        locality: formData.locality || '',
        micromarket: formData.micromarket || '',
        landmark: formData.landmark || ''
      });

      // Save inventory to Firestore
      const inventoryRef = doc(db, 'inventories', inventoryData.id);
      await setDoc(inventoryRef, inventoryData);

      const updatedEditedData = editedData.map(item =>
        item === editingItem ? { ...inventoryData, extractedData: editingItem } : item
      );
      setEditedData(updatedEditedData);

      // Reset forms and states
      setShowInventoryForm(false);
      setNewCenterData(null);
      setSelectedItem(null);

      // Optional: Trigger data refresh in parent component
      if (onDataRefresh) {
        onDataRefresh();
      }

      // Close modal after successful submission
      onClose();

      return inventoryData;
    } catch (error) {
      console.error('Error saving inventory:', error);
      setError(error.message || 'Failed to save inventory');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleInventoryFormCloseAdd = () => {
    setShowInventoryForm(false);
    setSelectedItem(null);
    setNewCenterData(null);
    setError(null);
  };

  const handleCenterFormClose = () => {
    setShowCenterForm(false);
    setSelectedItem(null);
    setNewCenterData(null);
    setError(null);
  };

  const handleSaveAll = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Get the current date
      const currentDate = new Date().toISOString();

      // Upload image to Firebase Storage
      let fileUrl = '';
      if (selectedFile) {
        // Create a reference to the file in Firebase Storage
        const storageRef = ref(storage, `inventory-images/${operatorId}/${selectedFile.name}-${Date.now()}`);

        // Upload the file
        const snapshot = await uploadBytes(storageRef, selectedFile);

        // Get the download URL
        fileUrl = await getDownloadURL(snapshot.ref);
        console.log('File uploaded successfully. URL:', fileUrl);
      }

      // Create the uploaded inventory data object
      const uploadedInventoryData = {
        fileUrl: fileUrl,
        fileName: selectedFile?.name || 'Unknown',
        dateReceived: currentDate,
        receivedFrom: operatorName,
        city: selectedCity
      };

      // Reference to the operator's document in Firestore
      const operatorRef = doc(db, 'operators', operatorId);

      // Get the current operator document
      const operatorDoc = await getDoc(operatorRef);

      // Initialize or update the uploadedInventory array
      let currentUploadedInventory = operatorDoc.exists() ?
        (operatorDoc.data().uploadedInventory || []) : [];

      // Add the new uploaded inventory data
      currentUploadedInventory.push(uploadedInventoryData);

      // Update the operator document with the new uploadedInventory array
      await updateDoc(operatorRef, {
        uploadedInventory: currentUploadedInventory
      });

      // Call the original onImport function with the data
      if (onImport) {
        await onImport({
          city: selectedCity,
          operatorName,
          data: editedData.map((item, index) => ({
            ...item,
            id: item.id || `temp-${index}`
          }))
        });
      }

      // Reset all form fields
      setSelectedFile(null);
      setPreviewImage(null);
      setImportData([]);
      setEditedData([]);
      setExtractedData([]);
      setCenterMappings({});
      setInventoryMappings({});
      setIsImageLoaded(false);
      setSelectedCity('');

      // Show success message
      alert('Data saved successfully!');

    } catch (error) {
      console.error('Error in handleSaveAll:', error);
      setError(error.message || 'Failed to save data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefreshData = async () => {
    try {
      setIsLoading(true);

      // Fetch centers and inventories
      const [centersResponse, inventoriesResponse] = await Promise.all([
        fetchCenters(),
        fetchInventories()
      ]);

      // Extract arrays from response objects
      const centersData = centersResponse.centers || [];
      const inventoriesData = inventoriesResponse.inventories || [];

      // Filter centers by operatorId
      const filteredCenters = centersData.filter(
        center => center.operatorId === operatorId
      );

      // Filter inventories by operatorId
      const filteredInventories = inventoriesData.filter(
        inventory => inventory.operatorId === operatorId
      );

      // Update centers and inventories states
      setCenter(filteredCenters);
      setExtractedInventories(filteredInventories);

      // If onDataRefresh prop is provided, call it
      if (onDataRefresh && typeof onDataRefresh === 'function') {
        onDataRefresh({
          centers: filteredCenters,
          inventories: filteredInventories
        });
      }
    } catch (error) {
      console.error('Error refreshing inventory data:', error);
      setError('Failed to refresh data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const removeUndefinedFields = (obj) => {
    const cleanedObj = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined && obj[key] !== null) {
        cleanedObj[key] = obj[key];
      }
    });
    return cleanedObj;
  };



  useEffect(() => {
    if (open) {
      // Fetch initial data
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const [centersResponse, inventoriesResponse] = await Promise.all([
            fetchCenters(),
            fetchInventories()
          ]);

          // Extract arrays from response objects
          const centersData = centersResponse.centers || [];
          const inventoriesData = inventoriesResponse.inventories || [];

          // Filter centers by operatorId
          const filteredCenters = centersData.filter(
            center => center.operatorId === operatorId
          );
          setCenterMappings(prevMappings => ({
            ...prevMappings,
            ...Object.fromEntries(filteredCenters.map(center => [
              center.centerName?.toLowerCase()?.trim(),
              center
            ]))
          }));

          // Filter inventories by operatorId
          const filteredInventories = inventoriesData.filter(
            inventory => inventory.operatorId === operatorId
          );
          setInventoryMappings(prevMappings => ({
            ...prevMappings,
            ...Object.fromEntries(filteredInventories.map(inventory => [
              inventory.inventoryName?.toLowerCase()?.trim(),
              inventory
            ]))
          }));

          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError('Failed to load data. Please try again.');
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [open, operatorId]);

  useEffect(() => {
    if (centerInventoryData?.existingInventories) {
      const inventories = centerInventoryData.existingInventories;
      setExtractedInventories(inventories);
      console.log('Existing inventories loaded:', inventories.map(inv => ({
        name: inv.inventoryName,
        size: inv.size
      })));
    }
  }, [centerInventoryData]);

  useEffect(() => {
    console.log('Current edited data:', editedData.map(item => ({
      name: item.inventoryName,
      size: item.size
    })));
  }, [editedData]);

  useEffect(() => {
    if (editedData.length > 0 && centers.length > 0) {
      const initialMappings = {};
      editedData.forEach((item, index) => {
        const isMatchedCenter = center.some(
          center => {
            const isMatch = center.centerName?.toLowerCase().trim() === item.centerName?.toLowerCase().trim();
            console.log('Center Matching Check:', {
              centerName: center.centerName,
              itemCenterName: item.centerName,
              isMatch
            });
            return isMatch;
          }
        );

        if (isMatchedCenter) {
          initialMappings[index] = centers.find(
            center => center.centerName?.toLowerCase().trim() === item.centerName?.toLowerCase().trim()
          ).id;
          // Update editedData with the matched center ID
          setEditedData(prevData =>
            prevData.map((dataItem, dataIndex) =>
              dataIndex === index
                ? { ...dataItem, centerId: initialMappings[index] }
                : dataItem
            )
          );
        }
      });
      setCenterMappings(initialMappings);
    }
  }, [editedData.length, centers.length]);

  useEffect(() => {
    if (editedData.length > 0 && existingInventories.length > 0) {

      const initialInventoryMappings = {};
      editedData.forEach((item, index) => {
        // Normalize inventory names for comparison
        const extractedName = item.inventoryName?.toLowerCase().trim();
        const matchingInventory = existingInventories.find(inv => {
          const isMatch =
            inv.inventoryName?.toLowerCase?.().trim() === extractedName &&
            String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim();

          return isMatch;
        });

        if (matchingInventory) {
          initialInventoryMappings[index] = matchingInventory.id;
          // Update editedData with the matched inventory ID and name
          setEditedData(prevData =>
            prevData.map((dataItem, dataIndex) =>
              dataIndex === index
                ? { ...dataItem, inventoryId: matchingInventory.id }
                : dataItem
            )
          );
        }
      });

      setInventoryMappings(initialInventoryMappings);
    }
  }, [editedData.length, existingInventories.length]);

  const handleClose = () => {
    setSelectedCity('');
    setSelectedFile(null);
    setPreviewImage(null);
    setImportData([]);
    setEditedData([]);
    setError(null);
    onClose();
  };



  const renderExtractedDataTable = () => (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p:0,
      }}>
        <Typography variant="h6">Extracted Inventory Data</Typography>
        <IconButton
          color="primary"
          onClick={handleRefreshData}
          disabled={isLoading}
          title="Refresh Inventory Data"
        >
          {isLoading ? <CircularProgress size={24} /> : <RestartAltIcon />}
        </IconButton>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Center Name</TableCell>
              <TableCell>Inventory Name</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editedData.map((item, index) => {
              const isMatchedCenter = center.some(
                center => {
                  const isMatch = center.centerName?.toLowerCase().trim() === item.centerName?.toLowerCase().trim();
                  console.log('Center Matching Check:', {
                    centerName: center.centerName,
                    itemCenterName: item.centerName,
                    isMatch
                  });
                  return isMatch;
                }
              );

              // Find the matching inventory to check size and availability
              const matchingInventory = extractedInventories.find(inv => {
                const inventoryNameMatch = inv.inventoryName?.toLowerCase?.().trim() === item.inventoryName?.toLowerCase?.().trim();
                const sizeMatch = String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim();
                
                console.log('Matching check for:', {
                  existingName: inv.inventoryName,
                  currentName: item.inventoryName,
                  existingSize: inv.size,
                  currentSize: item.size,
                  matches: { inventoryNameMatch, sizeMatch }
                });
                
                return inventoryNameMatch;  // Only match on name for highlighting
              });

              const isMatchedInventory = matchingInventory !== undefined;
              const isMatchedSize = isMatchedInventory && 
                String(matchingInventory.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim();
              const isMatchedAvailability = isMatchedInventory && 
                matchingInventory.availabilityDate?.toLowerCase?.().trim() === item.availabilityDate?.toLowerCase?.().trim();

              console.log('Match results for:', item.inventoryName, {
                isMatchedInventory,
                isMatchedSize,
                isMatchedAvailability
              });

              return (
                <TableRow key={index}>
                  <TableCell>
                    {centerEditModeIndex === index ? (
                      <TextField
                        value={item.centerName}
                        onChange={(e) => {
                          const newCenterName = e.target.value;
                          console.log('Updated centerName:', newCenterName); // Log new centerName value
                          setEditedData(prevData =>
                            prevData.map((dataItem, dataIndex) =>
                              dataIndex === index
                                ? { ...dataItem, centerName: newCenterName }
                                : dataItem
                            )
                          );
                        }}
                        sx={{
                          borderBottom: `2px solid ${isMatchedCenter ? '#4caf50' : '#f44336'}`,
                          color: isMatchedCenter ? '#4caf50' : '#f44336',
                          display: 'inline-block'
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          borderBottom: `2px solid ${isMatchedCenter ? '#4caf50' : '#f44336'}`,
                          color: isMatchedCenter ? '#4caf50' : '#f44336',
                          display: 'inline-block'
                        }}
                      >
                        {item.centerName}
                      </Typography>
                    )}
                    <IconButton size="small" onClick={() => setCenterEditModeIndex(centerEditModeIndex === index ? null : index)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {inventoryEditModeIndex === index ? (
                      <TextField
                        value={item.inventoryName}
                        onChange={(e) => {
                          const newInventoryName = e.target.value;
                          console.log('Updating inventory name:', {
                            old: item.inventoryName,
                            new: newInventoryName
                          });
                          setEditedData(prevData =>
                            prevData.map((dataItem, dataIndex) =>
                              dataIndex === index
                                ? { ...dataItem, inventoryName: newInventoryName }
                                : dataItem
                            )
                          );
                        }}
                        onBlur={() => {
                          // Force re-render of matching status
                          setEditedData(prev => [...prev]);
                        }}
                        sx={{
                          borderBottom: `2px solid ${isMatchedInventory ? '#4caf50' : '#f44336'}`,
                          color: isMatchedInventory ? '#4caf50' : '#f44336',
                          display: 'inline-block'
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          borderBottom: `2px solid ${isMatchedInventory ? '#4caf50' : '#f44336'}`,
                          color: isMatchedInventory ? '#4caf50' : '#f44336',
                          display: 'inline-block'
                        }}
                      >
                        {item.inventoryName}
                      </Typography>
                    )}
                    <IconButton size="small" onClick={() => setInventoryEditModeIndex(inventoryEditModeIndex === index ? null : index)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        borderBottom: `2px solid ${isMatchedSize ? '#4caf50' : '#f44336'}`,
                        color: isMatchedSize ? '#4caf50' : '#f44336',
                        display: 'inline-block'
                      }}
                    >
                      {item.size}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        borderBottom: `2px solid ${isMatchedAvailability ? '#4caf50' : '#f44336'}`,
                        color: isMatchedAvailability ? '#4caf50' : '#f44336',
                        display: 'inline-block'
                      }}
                    >
                      {item.availabilityDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const isExisting = extractedInventories.some(
                          inv =>
                            inv.inventoryName?.toLowerCase?.().trim() === item.inventoryName?.toLowerCase?.().trim() &&
                            String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
                        );
                        if (isExisting) {
                          handleEditClick(item);
                        } else {
                          handleAddClick(item);
                        }
                      }}
                      disabled={isLoading}
                      sx={{ 
                        transform: 'scale(0.8)',  // Make icon 80% of original size
                      }}
                    >
                      {item.id && editingItemId === item.id ? (
                        <SaveIcon />
                      ) : extractedInventories.some(
                        inv =>
                          inv.inventoryName?.toLowerCase?.().trim() === item.inventoryName?.toLowerCase?.().trim() &&
                          String(inv.size || '').toLowerCase().trim() === String(item.size || '').toLowerCase().trim()
                      ) ? (
                        <EditIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    borderBottom: '1px solid #e0e0e0'
                }}>
          Import Inventory for {operatorName}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                gap: 2,
                mb: 2,
                mt:3,
                alignItems: 'center',
                width: '100%'
              }}>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>City</InputLabel>
                  <Select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    label="City"
                    size="small"
                  >
                    {cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  type="file"
                  inputProps={{
                    accept: "image/*"
                  }}
                  onChange={handleFileSelect}
                  size="small"
                  
                />

                <Button
                  variant="contained"
                  onClick={handleImport}
                  disabled={!selectedFile || !selectedCity || isLoading}
                  sx={{ height: 40 }}
                >
                  {isLoading ? 'Processing...' : 'Extract'}
                </Button>

                {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              {previewImage && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Uploaded Image
                  </Typography>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 1,
                      height: '500px',
                      overflow: 'hidden'
                    }}
                  >
                    <TransformWrapper
                      ref={transformComponentRef}
                      initialScale={1}
                    >
                      {({ zoomIn, zoomOut, resetTransform }) => (
                        <>
                          <Box sx={{ mb: 1 }}>
                            <IconButton onClick={() => zoomIn()}>
                              <ZoomInIcon />
                            </IconButton>
                            <IconButton onClick={() => zoomOut()}>
                              <ZoomOutIcon />
                            </IconButton>
                            <IconButton onClick={() => resetTransform()}>
                              <RestartAltIcon />
                            </IconButton>
                          </Box>
                          <TransformComponent>
                            <img
                              src={previewImage}
                              alt="Preview"
                              style={{ maxWidth: '100%' }}
                            />
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>
                  </Paper>
                </>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              ) : (
                editedData.length > 0 && (
                  renderExtractedDataTable()
                )
              )}
            </Grid>
          </Grid>
        </DialogContent>

        {/* Inventory Form Dialog */}
        <InventoryForm
          open={isInventoryFormOpen}
          onClose={handleInventoryFormClose}
          onSubmit={handleInventoryFormSubmit}
          editMode={!!editingItem}
          importInitialData={editingItem}
          theme={theme}
          centers={centers}
          operators={[{ operatorName, id: operatorId }]}
        />

        <CenterForm
          open={showCenterForm}
          onClose={handleCenterFormClose}
          onSubmit={handleCenterFormSubmit}
          theme={theme}
          operators={operatorId ? [{ operatorName: operatorName || '', id: operatorId }] : []}
          initialData={{
            centerName: selectedItem?.centerName || '',
            operatorName: operatorName || '',
            operatorId: operatorId || '',
            city: selectedItem?.city || '',
            micromarket: selectedItem?.micromarket || '',
            locality: selectedItem?.locality || '',
          }}
          editMode={false}
        />

        <InventoryForm
          open={showInventoryForm}
          onClose={handleInventoryFormCloseAdd}
          onSubmit={handleInventoryFormSubmitAdd}
          centers={newCenterData ? [...centers, newCenterData] : centers}
          operators={[{
            id: operatorId,
            operatorName: operatorName
          }]}
          theme={theme}
          initialData={{
            ...selectedItem,
            centerId: newCenterData?.id || selectedItem?.centerId || '',
            operatorName: operatorName || '',
            operatorId: operatorId || '',
            inventoryName: selectedItem?.inventoryName || '',
            size: selectedItem?.size || '',
            availabilityDate: selectedItem?.availabilityDate || '',
          }}
          importInitialData={true}
        />

        {error && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              bgcolor: 'error.main',
              color: 'white',
              padding: 2,
              borderRadius: 1,
              zIndex: 9999
            }}
          >
            {error}
          </Box>
        )}
      </Dialog>
    </>
  );
};

// Add prop type validation
ImportInventoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onImport: PropTypes.func,
  onEditItem: PropTypes.func,
  operatorName: PropTypes.string,
  centerInventoryData: PropTypes.shape({
    centers: PropTypes.arrayOf(PropTypes.object),
    existingInventories: PropTypes.arrayOf(PropTypes.object)
  }),
  operatorId: PropTypes.string,
  theme: PropTypes.object,
  onDataRefresh: PropTypes.func
};


export default ImportInventoryModal;
