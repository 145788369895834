import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, useTheme, Divider, IconButton, Collapse } from '@mui/material';
import { Dashboard, Description, Settings, Logout, Business, LocationOn, Inventory, ExpandLess, ExpandMore, Person, Search } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { logoutUser } from '../../../firebase/auth';

const Sidebar = ({ mobileOpen, onClose, variant, drawerWidth, onMenuSelect }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [inventoryOpen, setInventoryOpen] = useState(false);

  const menuItems = [
    // { text: 'Dashboard', icon: <Dashboard />, path: '' },
    { text: 'Leads', icon: <Person />, path: 'leads' },
    { text: 'Proposals', icon: <Description />, path: 'proposals' },
    {
      text: "Action Items",
      icon: <AssignmentIcon />,
      path: "action-items",
    },
    {
      text: 'Inventory',
      icon: <Inventory />,
      hasSubMenu: true,
      subItems: [
        { text: 'Search', icon: <Search />, path: 'search' },
        { text: 'Operators', icon: <Business />, path: 'operators' },
        { text: 'Centers', icon: <LocationOn />, path: 'centers' },
        { text: 'Cabins', icon: <Inventory />, path: 'inventory' },
      ]
    }
  ];

  const handleInventoryClick = () => {
    setInventoryOpen(!inventoryOpen);
  };

  const handleNavigation = (path, text) => {
    onMenuSelect(text);
    navigate(path === '' ? '/private/dashboard' : `/private/dashboard/${path}`);
  };

  const handleLogout = async () => {
    try {
      const result = await logoutUser();
      if (result.success) {
        localStorage.removeItem('privateEmail');
        navigate('/private/login');
      } else {
        console.error('Logout failed:', result.error);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const drawerContent = (
    <>
      <List sx={{ px: 2, py: 2 }}>
        {menuItems.map((item) => {
          const currentPath = location.pathname;
          const isActive = item.hasSubMenu
            ? item.subItems.some(subItem => currentPath.includes(`/private/dashboard/${subItem.path}`))
            : item.path === ''
              ? currentPath === '/private/dashboard' || currentPath === '/private/dashboard/'
              : currentPath.includes(`/private/dashboard/${item.path}`);

          return (
            <React.Fragment key={item.text}>
              <ListItem
                button
                onClick={item.hasSubMenu ? handleInventoryClick : () => handleNavigation(item.path, item.text)}
                sx={{
                  py: 1,
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}08`,
                  },
                  ...(isActive && {
                    backgroundColor: 'rgba(1, 63, 46, 0.08)',
                    borderRadius: '20px',
                    '&:hover': {
                      backgroundColor: 'rgba(1, 63, 46, 0.08)',
                    },
                    '& .MuiListItemIcon-root': {
                      color: '#000000',
                    },
                    '& .MuiListItemText-primary': {
                      color: '#000000',
                      fontWeight: 500,
                    },
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    color: isActive ? '#000000' : '#000000',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '0.875rem',
                      color: isActive ? '#000000' : '#000000',
                      fontWeight: isActive ? 500 : 400,
                    },
                  }}
                />
                {item.hasSubMenu && (inventoryOpen ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>

              {item.hasSubMenu && (
                <Collapse in={inventoryOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => {
                      const isSubItemActive = currentPath.includes(`/private/dashboard/${subItem.path}`);

                      return (
                        <ListItem
                          button
                          key={subItem.text}
                          onClick={() => handleNavigation(subItem.path, subItem.text)}
                          sx={{
                            pl: 4,
                            py: 1,
                            borderRadius: '10px',
                            '&:hover': {
                              backgroundColor: `${theme.palette.primary.main}08`,
                            },
                            ...(isSubItemActive && {
                              backgroundColor: 'rgba(1, 63, 46, 0.08)',
                              borderRadius: '20px',
                              '&:hover': {
                                backgroundColor: 'rgba(1, 63, 46, 0.08)',
                              },
                              '& .MuiListItemIcon-root': {
                                color: '#000000',
                              },
                              '& .MuiListItemText-primary': {
                                color: '#000000',
                                fontWeight: 500,
                              },
                            }),
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 40,
                              color: isSubItemActive ? '#000000' : '#000000',
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              '& .MuiListItemText-primary': {
                                fontSize: '0.875rem',
                                color: isSubItemActive ? '#000000' : '#000000',
                                fontWeight: isSubItemActive ? 500 : 400,
                              },
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>

      <Divider />
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        bgcolor: 'background.paper',
        p: 2,
        borderTop: 1,
        borderColor: 'divider',
        mt: 'auto'
      }}>
        <IconButton
          sx={{
            p: 1,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' }
          }}
        >
          <AccountCircleIcon />
        </IconButton>
        <IconButton
          onClick={() => handleNavigation('settings', 'Settings')}
          sx={{
            p: 1,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' }
          }}
        >
          <Settings />
        </IconButton>
        <IconButton
          onClick={handleLogout}
          sx={{
            p: 1,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' }
          }}
        >
          <Logout />
        </IconButton>
      </Box>
    </>
  );

  return (
    <Drawer
      variant={variant}
      open={variant === 'temporary' ? mobileOpen : true}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better mobile performance
      }}
      sx={{
        display: { xs: 'block', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          ...(variant === 'temporary' && {
            mt: 0,
            height: '100%',
          }),
          ...(variant === 'permanent' && {
            mt: '64px',
            height: 'calc(100vh - 64px)',
          }),
        },
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default Sidebar;
