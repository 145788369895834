import React, { useState } from "react";
import Gofloaters from "../../../assest/image/header.png"
import Logo from "../../../assest/image/CBRE_green.png"
import ClientLogo from "../../../assest/image/accenture-logo.png"
import "../../../Styles/OfficeProposal.css"
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

export default function OfficeProposal({ proposal, isEditable, onSaveClientName, onSaveRequirements }) {
    const requirementsList = proposal?.requirements?.split('\n') || [];
    const [isEditingName, setIsEditingName] = useState(false);
    const [editedName, setEditedName] = useState(proposal?.clientName || '');
    const [isEditingRequirements, setIsEditingRequirements] = useState(false);
    const [editedRequirements, setEditedRequirements] = useState(requirementsList);

    const handleEditClientName = () => {
        setIsEditingName(true);
        setEditedName(proposal?.clientName || '');
    };

    const handleSaveClientName = async () => {
        const success = await onSaveClientName(editedName);
        if (success) {
            setIsEditingName(false);
        }
    };

    const handleCancelEdit = () => {
        setIsEditingName(false);
        setEditedName(proposal?.clientName || '');
    };

    const handleEditRequirement = () => {
        setIsEditingRequirements(true);
        setEditedRequirements([...requirementsList]);
    };

    const handleSaveRequirements = async () => {
        const success = await onSaveRequirements(editedRequirements);
        if (success) {
            setIsEditingRequirements(false);
        }
    };

    const handleCancelRequirements = () => {
        setIsEditingRequirements(false);
        setEditedRequirements([...requirementsList]);
    };

    const handleRequirementChange = (index, value) => {
        const newRequirements = [...editedRequirements];
        newRequirements[index] = value;
        setEditedRequirements(newRequirements);
    };

    const handleAddRequirement = () => {
        setEditedRequirements([...editedRequirements, '']);
    };

    const handleDeleteRequirement = (index) => {
        const newRequirements = editedRequirements.filter((_, i) => i !== index);
        setEditedRequirements(newRequirements);
    };

    return (
        <div className="container office-proposal-container">
            <div className="row align-items-center">
                {/* Left Section */}
                <div className="col-12 col-md-6 text-start">
                    <img
                        src={'https://assets.gofloaters.com/logo.png'}
                        alt="GoFloaters Logo"
                        className="text-start"
                        style={{ maxWidth: 200, height: "auto" }}
                    /> 
                    <div className="text-start mt-4">
                        <p className="display-5" style={{ marginTop: "30px" }}>Flexible Office Proposal for </p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isEditingName ? (
                                <>
                                    <p 
                                        className="display-5" 
                                        style={{ 
                                            marginBottom: 0,
                                            outline: 'none',
                                            borderBottom: '2px solid #013F2E',
                                            minWidth: '100px'
                                        }}
                                        contentEditable
                                        suppressContentEditableWarning
                                        onBlur={(e) => setEditedName(e.target.textContent)}
                                    >
                                        {proposal.clientName}
                                    </p>
                                    <IconButton 
                                        onClick={handleSaveClientName}
                                        size="small"
                                        sx={{ ml: 1 }}
                                    >
                                        <CheckIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                    </IconButton>
                                    <IconButton 
                                        onClick={handleCancelEdit}
                                        size="small"
                                    >
                                        <CloseIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    <p className="display-5" style={{ marginBottom: 0 }}>{proposal.clientName}</p>
                                    {isEditable && (
                                        <IconButton 
                                            onClick={handleEditClientName}
                                            size="small"
                                            sx={{ ml: 1 }}
                                        >
                                            <EditIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="requirement-head mt-4">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className="text-start mx-3" style={{ marginBottom: 0 }}>Requirements :</h3>
                                {isEditable && !isEditingRequirements && (
                                    <IconButton 
                                        onClick={handleEditRequirement}
                                        size="small"
                                    >
                                        <EditIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                    </IconButton>
                                )}
                                {isEditingRequirements && (
                                    <>
                                        <IconButton 
                                            onClick={handleSaveRequirements}
                                            size="small"
                                            sx={{ ml: 1 }}
                                        >
                                            <CheckIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                        </IconButton>
                                        <IconButton 
                                            onClick={handleCancelRequirements}
                                            size="small"
                                        >
                                            <CloseIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                        </IconButton>
                                        <IconButton 
                                            onClick={handleAddRequirement}
                                            size="small"
                                        >
                                            <AddIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                            <ul style={{ listStyle: isEditingRequirements ? 'none' : 'inherit' }}>
                                {(isEditingRequirements ? editedRequirements : requirementsList).map((requirement, index) => (
                                    <li key={index} className="text-start requirement-li mx-3" style={{ marginBottom: '8px' }}>
                                        {isEditingRequirements ? (
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <p
                                                    style={{ 
                                                        margin: 0,
                                                        padding: '4px 8px',
                                                        outline: 'none',
                                                        borderBottom: '2px solid #013F2E',
                                                        flex: 1,
                                                        fontSize: '1rem'
                                                    }}
                                                    contentEditable
                                                    suppressContentEditableWarning
                                                    onBlur={(e) => handleRequirementChange(index, e.target.textContent)}
                                                >
                                                    {requirement}
                                                </p>
                                                <IconButton 
                                                    onClick={() => handleDeleteRequirement(index)}
                                                    size="small"
                                                >
                                                    <DeleteIcon sx={{ fontSize: '1.2rem', color: '#013F2E' }} />
                                                </IconButton>
                                            </div>
                                        ) : requirement}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* <p>26-Nov-2024</p> */}
                </div>
                {/* Right Section */}
                <div className="col-12 col-md-6">
                    <img
                        src={Gofloaters}
                        alt="Office Space"
                        className="rounded img-fluid align-item-start mb-5"
                    />
                </div>
            </div>
            <hr/>
        </div>
    )
}
