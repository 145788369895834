import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Chip,
  CardActionArea,
  Divider,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';

const OperatorCenters = () => {
  const { operatorId } = useParams();
  const navigate = useNavigate();
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [operatorDetails, setOperatorDetails] = useState(null);
  const [selectedCity, setSelectedCity] = useState('all');

  console.log('operatorDetails', operatorDetails);

  useEffect(() => {
    const fetchCenters = async () => {
      try {
        setLoading(true);
        const getOperatorCenters = httpsCallable(functions, 'getOperatorCenters');
        const result = await getOperatorCenters({ operatorId });
        const data = result.data; // Firebase Functions return data in result.data

        console.log('API Response:', data);
        console.log('operatorId', operatorId);
        if (data.success) {
          setCenters(data.centers);

          // Set operator details from the API response
          if (data.operator) {
            const opDetails = {
              id: operatorId,
              operatorName: data.operator.operatorName || data.operator.displayName || data.operator.name || 'Unknown Operator',
              logo: data.operator.logos?.[0]?.url || data.operator.photoURL || data.operator.logo,
            };
            setOperatorDetails(opDetails);
          } else if (data.centers && data.centers.length > 0) {
            // Fallback to center data if operator details not available
            const firstCenter = data.centers[0];
            setOperatorDetails({
              id: operatorId,
              operatorName: firstCenter.operatorName || 'Unknown Operator',
              logo: firstCenter.operatorLogo,
              description: firstCenter.operatorDescription
            });
          }
        } else {
          setError(data.error || 'Failed to load centers');
        }
      } catch (err) {
        console.error('Error fetching centers:', err);
        setError(err.message || 'Failed to load centers');
      } finally {
        setLoading(false);
      }
    };

    if (operatorId) {
      fetchCenters();
    } else {
      setError('Operator ID is required');
    }
  }, [operatorId]);

  // Get unique cities from centers
  const cities = useMemo(() => {
    const uniqueCities = [...new Set(centers.map(center => center.address?.city || center.city))].filter(Boolean);
    return ['all', ...uniqueCities];
  }, [centers]);

  // Filter centers based on selected city
  const filteredCenters = useMemo(() => {
    if (selectedCity === 'all') return centers;
    return centers.filter(center => (center.address?.city || center.city) === selectedCity);
  }, [centers, selectedCity]);

  const handleCenterClick = (centerId) => {
    navigate(`/public/operator/${operatorId}/${centerId}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Operator Header */}
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          component="img"
          src={operatorDetails?.logo}
          alt={operatorDetails?.operatorName}
          sx={{
            width: 120,
            height: 60,
            objectFit: 'contain',
            borderRadius: 1,
          }}
        />

      </Box>

      {/* Filter Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ mb: 0, fontWeight: 600 }}>
          Available Centers ({filteredCenters.length})
        </Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filter by City</InputLabel>
          <Select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            label="Filter by City"
            size="small"
          >
            {cities.map((city) => (
              <MenuItem key={city} value={city}>
                {city === 'all' ? 'All Cities' : city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        {filteredCenters.map((center) => (
          <Grid item xs={12} sm={6} md={4} key={center.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              <CardActionArea onClick={() => handleCenterClick(center.id)}>
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    component="img"
                    height="220"
                    image={center.photos?.[0]?.url || ''}
                    alt={center.centerName}
                    sx={{ objectFit: 'cover' }}
                  />
                  {/* Operator Logo */}
                  {/* <Avatar
                    src={operatorDetails?.logo}
                    alt={operatorDetails?.operatorName}
                    sx={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      width: 40,
                      height: 40,
                      border: '2px solid white',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      bgcolor: 'primary.main',
                      color: 'white'
                    }}
                  >
                    {!operatorDetails?.logo && <StorefrontIcon />}
                  </Avatar> */}
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(transparent, rgba(0,0,0,0.8))',
                      p: 2,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
                      {center.centerName}
                    </Typography>
                  </Box>
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <LocationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="body2" color="text.secondary">
                        {center.locality}, {center.city}
                      </Typography>
                    </Box>

                  </Box>

                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OperatorCenters;
