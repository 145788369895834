import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  StyledEngineProvider,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { useSelector } from 'react-redux';
import { addToProposal } from '../../../Services/FirebaseUtils';

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: document.body
      },
      styleOverrides: {
        root: {
          zIndex: 11000
        },
        paper: {
          zIndex: 11000
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          zIndex: 11000
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          zIndex: 11000
        }
      }
    }
  }
});

const AddToProposalDialog = ({ open, onClose, option }) => {
  const [selectedProposal, setSelectedProposal] = useState('');
  const [dialogError, setDialogError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { items: proposals = [], loading: proposalsLoading } = useSelector((state) => state.proposals || { items: [], loading: false });

  console.log('proposals', proposals);

  const handleClose = () => {
    setSelectedProposal('');
    setDialogError('');
    onClose();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async () => {
    if (!selectedProposal) {
      setDialogError('Please select a proposal');
      return;
    }

    try {
      await addToProposal(selectedProposal, option);
      setSnackbar({
        open: true,
        message: 'Successfully added to proposal',
        severity: 'success'
      });
      handleClose();
    } catch (error) {
      console.error('Error adding to proposal:', error);
      setDialogError(error.message);
      setSnackbar({
        open: true,
        message: error.message || 'Error adding to proposal',
        severity: 'error'
      });
    }
  };

  if (proposalsLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog 
          open={open} 
          onClose={handleClose} 
          maxWidth="sm" 
          fullWidth
          sx={{ 
            zIndex: 10000,
            '& .MuiDialog-paper': { 
              zIndex: 10000 
            },
            '& .MuiBackdrop-root': {
              zIndex: 9999
            },
            '& .MuiPopover-root': {
              zIndex: 11000
            }
          }}
        >
          <DialogContent>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
              <CircularProgress />
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
  if (!proposals || proposals.length === 0) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="add-to-proposal-dialog-title"
          maxWidth="sm"
          fullWidth
          sx={{ 
            zIndex: 10000,
            '& .MuiDialog-paper': { 
              zIndex: 10000 
            },
            '& .MuiBackdrop-root': {
              zIndex: 9999
            },
            '& .MuiPopover-root': {
              zIndex: 11000
            }
          }}
        >
          <DialogTitle>Add to Proposal</DialogTitle>
          <DialogContent>
            <Typography sx={{ py: 3 }}>No proposals available. Please create a proposal first.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Dialog 
          open={open} 
          onClose={handleClose} 
          maxWidth="sm" 
          fullWidth
          sx={{ 
            zIndex: 10000,
            '& .MuiDialog-paper': { 
              zIndex: 10000 
            },
            '& .MuiBackdrop-root': {
              zIndex: 9999
            },
            '& .MuiPopover-root': {
              zIndex: 11000
            }
          }}
        >
          <DialogTitle>Add to Proposal</DialogTitle>
          <DialogContent>
            <FormControl fullWidth error={!!dialogError} sx={{ mt: 2 }}>
              <InputLabel id="proposal-select-label">Select Proposal</InputLabel>
              <Select
                labelId="proposal-select-label"
                id="proposal-select"
                value={selectedProposal}
                label="Select Proposal"
                onChange={(e) => setSelectedProposal(e.target.value)}
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 11000
                    }
                  }
                }}
              >
                {Array.isArray(proposals) ? proposals.map((proposal) => (
                  <MenuItem 
                    key={proposal.id} 
                    value={proposal.id}
                  >
                    {proposal.name || proposal.clientName || 'Untitled Proposal'}
                  </MenuItem>
                )) : null}
              </Select>
              {dialogError && <FormHelperText>{dialogError}</FormHelperText>}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ zIndex: 11000 }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default AddToProposalDialog;
