import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Rating,
  IconButton,
  styled,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  Button,
  WarningAmberIcon,
  Modal,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseIcon from '@mui/icons-material/Close';

import { GoogleMap, InfoWindowF, LoadScript, Marker, MarkerF, useJsApiLoader } from '@react-google-maps/api';

import WifiIcon from '@mui/icons-material/Wifi';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import WcIcon from '@mui/icons-material/Wc';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import FastfoodIcon from '@mui/icons-material/Fastfood';

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

// Styled components
const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '500px',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  borderRadius: 12,
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const NavigationDots = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: theme.spacing(1),
  zIndex: 1,
}));

const Dot = styled(Box)(({ active, theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.5)',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.8)',
  },
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 500,
  color: '#1a1a1a',
  marginBottom: theme.spacing(1),
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 400,
  color: '#666',
  marginBottom: theme.spacing(3),
}));

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  color: '#666',
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const containerStyle = {
  width: '100%',
  height: '300px'
};

const fullscreenContainerStyle = {
  width: '100vw',
  height: '100vh'
};

// Custom map styles
const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ADD8E6"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

const AmenitiesSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: '#fff',
}));

const AmenityCategory = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  color: '#333',
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const AmenityGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AmenityItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const AmenityIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #e0e0e0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
}));

const AdvantageSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: "10px 15px 20px 15px",
  border: "1px solid #2193b0",
  borderRadius: "10px",
  backgroundImage: "linear-gradient(to bottom, rgba(234, 202, 175, 0.49), rgba(188, 175, 146, 0.1))",
}));

const AdvantageGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const AdvantageItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  '& .MuiSvgIcon-root': {
    color: '#00A0DC',
    fontSize: 32,
  },
}));

const StickyColumn = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(0),
  height: 'fit-content',
  paddingTop: theme.spacing(0),
}));

const MapModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MapModalContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
}));

const FullscreenButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const InventoryDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { inventory: passedInventory, centerData: passedCenterData } = location.state || {};
  const [inventory, setInventory] = useState(passedInventory);
  const [centerData, setCenterData] = useState(passedCenterData);
  const [zoom, setZoom] = useState(-11);
  const [map, setMap] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);

  console.log('Inventory:', inventory);
  console.log('Center Data:', centerData);

  const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }).format(price)
      : "N/A";

  const locations = centerData ? [{
    lat: Number(centerData.latitude) || 13.0827,
    lng: Number(centerData.longitude) || 80.2707,
    centerName: centerData.centerName,
    locality: centerData.address,
    listPrice: formatPrice(centerData.listPrice),
  }] : [];

  console.log('Locations:', locations);

  const [center, setCenter] = useState(() => {
    if (locations.length > 0) {
      return { lat: locations[0].lat, lng: locations[0].lng };
    }
    return { lat: 13.0827, lng: 80.2707 };
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs',
    libraries: ['places', 'maps']
  });

  const markerIcon = isLoaded && window.google ? {
    path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
    fillColor: '#2193B0',
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: '#ffffff',
    scale: 2,
    anchor: new window.google.maps.Point(12, 22),
    labelOrigin: new window.google.maps.Point(12, 9)
  } : null;

  const onLoad = useCallback((map) => {
    if (locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
      });
      map.fitBounds(bounds);
    }
    setMap(map);
  }, [locations]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  // If no inventory or centerData, show error
  if (!inventory || !centerData) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '50vh'
        }}>
          <Box
            sx={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              backgroundColor: 'error.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2
            }}
          >
            <Typography variant="h3" color="white">
              !
            </Typography>
          </Box>
          <Typography variant="h5" color="error" gutterBottom>
            Data Not Found
          </Typography>
          <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 2 }}>
            The inventory or center information is missing.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/public/operator')}
          >
            Go to Operators
          </Button>
        </Box>
      </Container>
    );
  }

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? inventory.photos.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === inventory.photos.length - 1 ? 0 : prev + 1
    );
  };

  const handleMapFullscreen = () => {
    setIsMapFullscreen(!isMapFullscreen);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header Section */}
      <Box sx={{ mb: 4 }}>
        {/* Breadcrumb Navigation */}
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" sx={{ color: '#666' }} />}
          sx={{ mb: 3 }}
        >
          <BreadcrumbLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/public/operator/${centerData?.operatorId}/${centerData?.id}`);
            }}
          >
            Center
          </BreadcrumbLink>
          <BreadcrumbLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            {centerData?.city || 'N/A'}
          </BreadcrumbLink>
          <Typography color="#666">
            {centerData?.centerName || 'N/A'}
          </Typography>
        </Breadcrumbs>

        {/* Title */}
        <HeaderTitle variant="h1" className='fw-bold text-dark'>
          {inventory?.inventoryName || 'N/A'}
        </HeaderTitle>
        <SubTitle  className='text-secondary mx-2 display-3'>
          {centerData?.address || 'N/A'}
        </SubTitle>
      </Box>

      {/* Rest of the content */}
      <Grid container spacing={3}>
        {/* Left Column - Image Gallery */}
        <Grid item xs={12} md={8}>
          <ImageContainer>
            <img
              src={inventory.photos?.[currentImageIndex]?.url || 'N/A'}
              alt={`Space ${currentImageIndex + 1}`}
            />
            <NavigationButton 
              onClick={handlePrevImage}
              disabled={currentImageIndex === 0}
              sx={{ left: 8 }}
            >
              <KeyboardArrowLeftIcon />
            </NavigationButton>
            <NavigationButton
              onClick={handleNextImage}
              disabled={currentImageIndex === (inventory.photos?.length || 1) - 1}
              sx={{ right: 8 }}
            >
              <KeyboardArrowRightIcon />
            </NavigationButton>
            <NavigationDots>
              {inventory.photos?.map((_, index) => (
                <Dot
                  key={index}
                  active={index === currentImageIndex}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </NavigationDots>
          </ImageContainer>

          {/* Amenities Section */}
          <AmenitiesSection>
            <Typography variant="h3" gutterBottom>
              Amenities
            </Typography>

            {/* Space Info */}
            <AmenityCategory>
              Space info
            </AmenityCategory>
            <AmenityGrid container spacing={3}>
              {centerData?.amenities
                ?.filter(amenity =>
                  ['wifi', 'power', 'internet', 'backup'].some(keyword =>
                    amenity.toLowerCase().includes(keyword)
                  )
                )
                .map((amenity, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <AmenityItem>
                      <AmenityIcon>
                        {amenity.toLowerCase().includes('wifi') || amenity.toLowerCase().includes('internet') ? (
                          <WifiIcon />
                        ) : amenity.toLowerCase().includes('power') || amenity.toLowerCase().includes('backup') ? (
                          <BatteryChargingFullIcon />
                        ) : (
                          <WifiIcon />
                        )}
                      </AmenityIcon>
                      <Typography>{amenity}</Typography>
                    </AmenityItem>
                  </Grid>
                ))}
            </AmenityGrid>

            {/* Building Info */}
            <AmenityCategory>
              Building Info
            </AmenityCategory>
            <AmenityGrid container spacing={3}>
              {centerData?.amenities
                ?.filter(amenity =>
                  ['ac', 'metro', 'rest', 'coffee', 'pantry', 'meeting', 'food'].some(keyword =>
                    amenity.toLowerCase().includes(keyword)
                  )
                )
                .map((amenity, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <AmenityItem>
                      <AmenityIcon>
                        {amenity.toLowerCase().includes('ac') ? (
                          <AcUnitIcon />
                        ) : amenity.toLowerCase().includes('metro') ? (
                          <DirectionsTransitIcon />
                        ) : amenity.toLowerCase().includes('rest') ? (
                          <WcIcon />
                        ) : amenity.toLowerCase().includes('coffee') ? (
                          <LocalCafeIcon />
                        ) : amenity.toLowerCase().includes('pantry') ? (
                          <RestaurantIcon />
                        ) : amenity.toLowerCase().includes('meeting') ? (
                          <MeetingRoomIcon />
                        ) : amenity.toLowerCase().includes('food') ? (
                          <FastfoodIcon />
                        ) : (
                          <RestaurantIcon />
                        )}
                      </AmenityIcon>
                      <Typography>{amenity}</Typography>
                    </AmenityItem>
                  </Grid>
                ))}
            </AmenityGrid>
          </AmenitiesSection>

          {/* GoFloaters Advantage Section */}
          <AdvantageSection>
            <Typography variant="h4" gutterBottom>
              GoFloaters Advantage
            </Typography>
            <AdvantageGrid container spacing={4}>
              <Grid item xs={12} md={6}>
                <AdvantageItem>
                  <MonetizationOnOutlinedIcon />
                  <Typography variant="h6">
                    Lowest Price Guaranteed
                  </Typography>
                </AdvantageItem>
                <AdvantageItem>
                  <CalendarTodayOutlinedIcon />
                  <Typography variant="h6">
                    Free Cancellation
                  </Typography>
                </AdvantageItem>
                <AdvantageItem>
                  <VerifiedOutlinedIcon />
                  <Typography variant="h6">
                    Verified listings
                  </Typography>
                </AdvantageItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <AdvantageItem>
                  <EventAvailableOutlinedIcon />
                  <Typography variant="h6">
                    Free Rescheduling
                  </Typography>
                </AdvantageItem>
                <AdvantageItem>
                  <VerifiedOutlinedIcon />
                  <Typography variant="h6">
                    Instant Confirmations
                  </Typography>
                </AdvantageItem>
              </Grid>
            </AdvantageGrid>
          </AdvantageSection>
        </Grid>

        {/* Right Column - Rating, Map, and Price */}
        <Grid item xs={12} md={4}>
          <StickyColumn>
            {/* Rating Section */}
            <Card sx={{ mb: 3, p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" component="span" sx={{ mr: 1 }}>
                  {inventory.rating || 3.5}
                </Typography>
                <Rating
                  value={inventory.rating || 3.15}
                  precision={0.1}
                  readOnly
                  sx={{
                    ml: 1,
                    '& .MuiRating-iconFilled': {
                      color: '#FFB400',
                    },
                    '& .MuiRating-iconEmpty': {
                      color: '#E0E0E0',
                    },
                  }}
                />
              </Box>
              <Link
                href="#"
                underline="hover"
                sx={{
                  color: 'text.primary',
                  fontWeight: 500,
                  '&:hover': { color: 'primary.main' },
                }}
              >
                See all {inventory.reviewCount || 482} reviews
              </Link>
            </Card>

            {/* Map Section */}
            <Card sx={{ mb: 3, overflow: 'hidden', position: 'relative' }}>
              <FullscreenButton onClick={handleMapFullscreen}>
                <FullscreenIcon />
              </FullscreenButton>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={zoom}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  options={mapOptions}
                >
                  {locations.map((location, index) => (
                    <MarkerF
                      key={index}
                      position={{ lat: location.lat, lng: location.lng }}
                      options={{
                        icon: markerIcon,
                        label: {
                          text: `${index + 1}`,
                          color: '#FFFFFF',
                          fontSize: '14px',
                          fontWeight: 'bold'
                        }
                      }}
                    />
                  ))}
                </GoogleMap>
              ) : (
                <Box sx={{ height: '200px' }} />
              )}
            </Card>

            {/* Fullscreen Map Modal */}
            <MapModal
              open={isMapFullscreen}
              onClose={() => setIsMapFullscreen(false)}
            >
              <MapModalContent>
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <FullscreenButton 
                    onClick={handleMapFullscreen}
                    sx={{ 
                      bgcolor: 'white',
                      '&:hover': { bgcolor: 'white' }
                    }}
                  >
                    <FullscreenExitIcon />
                  </FullscreenButton>
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={fullscreenContainerStyle}
                      center={center}
                      zoom={zoom}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={mapOptions}
                    >
                      {locations.map((location, index) => (
                        <MarkerF
                          key={index}
                          position={{ lat: location.lat, lng: location.lng }}
                          options={{
                            icon: markerIcon,
                            label: {
                              text: `${index + 1}`,
                              color: '#FFFFFF',
                              fontSize: '14px',
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      ))}
                    </GoogleMap>
                  )}
                </Box>
              </MapModalContent>
            </MapModal>

            {/* Price Card */}
            <Card sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>
                ₹{centerData?.listPrice || 0}
              </Typography>
              <Button
                variant="contained"
                fullWidth
                size="large"
                sx={{
                  backgroundColor: '#2193b0',
                  '&:hover': {
                    backgroundColor: '#1c7a8e'
                  }
                }}
              >
                Book Now
              </Button>
            </Card>
          </StickyColumn>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InventoryDetails;
