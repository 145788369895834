import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Modal,
  Select,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { addInventory } from '../../Services/FirebaseUtils';
import { FiUpload } from "react-icons/fi";
import CenterForm from './CenterForm';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { storage, db } from '../../firebase/config';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const initialFormState = {
  operatorId: '',
  operatorName: '',
  centerId: '',
  centerName: '',
  inventoryName: '',
  size: '',
  floor: '',
  active: false,
  lastUpdatedDate: '',
  cabinNumber: '',
  inventoryType: '',
  quantity: '',
  availabilityDate: '',
  description: '',
  city: '',
  locality: '',
  micromarket: '',
  address: '',
  googleMapsUrl: '',
  centerDetails: '',
  inventoryDetails: '',
  section: '',
  photos: []
};

const photoTypes = [
  'Breakout Area',
  'Conference Room',
  'Manager Cabin',
  'Phone Booth',
  'Private Cabin',
  'Representative Breakout Area',
  'Representative Conference Room',
  'Representative Manager Cabin',
  'Representative Phone Booth',
  'Representative Private Cabin'
];

const InventoryForm = ({
  open,
  onClose,
  centers = [],
  operators = [],
  theme,
  onSubmit,
  initialData,
  importInitialData,
  editMode
}) => {
  const [openCenterForm, setOpenCenterForm] = useState(false);

  // Choose which initial data to use - prioritize importInitialData
  const formInitialData = importInitialData || initialData;
  console.log('InventoryForm formInitialData:', formInitialData);

  const [formData, setFormData] = useState({
    ...initialFormState,
    operatorId: formInitialData?.operatorId || '',
    operatorName: formInitialData?.operatorName || '',
    centerId: formInitialData?.centerId || '',
    centerName: formInitialData?.centerName || '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [photos, setPhotos] = useState([{ type: '', file: null, description: '' }]);

  useEffect(() => {
    console.log('InventoryForm editMode:', editMode);
    console.log('InventoryForm formInitialData:', formInitialData);
    
    if (editMode && formInitialData) {
      // Helper function to safely format date
      const formatDate = (dateStr) => {
        if (!dateStr) return new Date().toISOString().split('T')[0];
        
        // If it's already in YYYY-MM-DD format, return as is
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          return dateStr;
        }

        // Handle DD/MM/YYYY format
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
          const [day, month, year] = dateStr.split('/');
          return `${year}-${month}-${day}`;
        }
        
        try {
          // Try to parse the date string
          const date = new Date(dateStr);
          if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateStr);
            return new Date().toISOString().split('T')[0];
          }
          return date.toISOString().split('T')[0];
        } catch (e) {
          console.error('Error parsing date:', dateStr, e);
          return new Date().toISOString().split('T')[0];
        }
      };

      // Format dates safely
      const availDate = formatDate(formInitialData.availabilityDate);
      const lastUpdateDate = formatDate(formInitialData.lastUpdatedDate);

      console.log('Formatted dates:', { 
        original: formInitialData.availabilityDate,
        formatted: availDate
      });

      // Initialize photos from formInitialData
      if (formInitialData.photos && formInitialData.photos.length > 0) {
        setPhotos(formInitialData.photos.map(photo => ({
          type: photo.type || photo.name || '',
          description: photo.description || '',
          url: photo.url || '',
          file: null
        })));
      } else {
        setPhotos([{ type: '', file: null, description: '' }]);
      }

      setFormData({
        operatorId: formInitialData.operatorId || '',
        operatorName: formInitialData.operatorName || '',
        centerId: formInitialData.centerId || '',
        centerName: formInitialData.centerName || '',
        inventoryName: formInitialData.inventoryName || '',
        size: formInitialData.size || '',
        floor: formInitialData.floor || '',
        active: formInitialData.active || true,
        lastUpdatedDate: lastUpdateDate,
        cabinNumber: formInitialData.cabinNumber || '',
        inventoryType: formInitialData.inventoryType || '',
        quantity: formInitialData.quantity || '1',
        availabilityDate: availDate,
        description: formInitialData.inventoryDescription || formInitialData.description || '',
        city: formInitialData.city || '',
        locality: formInitialData.locality || '',
        micromarket: formInitialData.micromarket || '',
        address: formInitialData.address || '',
        googleMapsUrl: formInitialData.googleMapsUrl || '',
        centerDetails: formInitialData.centerDetails || '',
        inventoryDetails: formInitialData.inventoryDetails || '',
        section: formInitialData.section || '',
        photos: formInitialData.photos || []
      });
    } else {
      setFormData({
        ...initialFormState,
        lastUpdatedDate: new Date().toISOString().split('T')[0],
        availabilityDate: new Date().toISOString().split('T')[0],
        quantity: '1'
      });
      setPhotos([{ type: '', file: null, description: '' }]);
    }
  }, [editMode, formInitialData]);

  const handleAddPhoto = () => {
    setPhotos([...photos, { type: '', file: null, description: '' }]);
  };

  const handleRemovePhoto = (index) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  };

  const handlePhotoChange = (index, field, value) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index][field] = value;
    setPhotos(updatedPhotos);
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const updatedPhotos = [...photos];
      updatedPhotos[index].file = file;
      setPhotos(updatedPhotos);
    }
  };

  const handleReset = () => {
    if (!editMode) {
      setFormData({
        ...initialFormState,
        lastUpdatedDate: new Date().toISOString().split('T')[0],
        availabilityDate: new Date().toISOString().split('T')[0],
        quantity: '1'
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for operator selection
    if (name === 'operatorId') {
      const selectedOperator = operators.find(op => op.id === value);
      setFormData(prev => ({
        ...prev,
        operatorId: value,
        operatorName: selectedOperator?.operatorName || ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.inventoryName?.trim()) {
      newErrors.inventoryName = "This field is mandatory. Enter a value";
    }
    if (!formData.inventoryType) {
      newErrors.inventoryType = "This field is mandatory. Enter a value";
    }
    if (!formData.size) {
      newErrors.size = "This field is mandatory. Enter a value";
    }
    if (!formData.quantity) {
      newErrors.quantity = "This field is mandatory. Enter a value";
    }
    if (!formData.availabilityDate) {
      newErrors.availabilityDate = "This field is mandatory. Enter a value";
    }
    if (!formData.lastUpdatedDate) {
      newErrors.lastUpdatedDate = "This field is mandatory. Enter a value";
    }
    if (!formData.centerName) {
      newErrors.centerName = "This field is mandatory. Enter a value";
    }
    if (!formData.operatorId) {
      newErrors.operatorName = "This field is mandatory. Enter a value";
    }
    if (!formData.locality) {
      newErrors.locality = "This field is mandatory. Enter a value";
    }
    if (!formData.city) {
      newErrors.city = "This field is mandatory. Enter a value";
    }
    if (!formData.micromarket) {
      newErrors.micromarket = "This field is mandatory. Enter a value";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    try {
      // Prepare photos array with both existing and new photos
      const uploadedPhotoUrls = await Promise.all(
        photos.map(async (photo) => {
          // If photo already has a URL and no new file, keep the existing photo
          if (photo.url && !photo.file) {
            return {
              type: photo.type || '',
              description: photo.description || '',
              url: photo.url
            };
          }

          // If there's a new file, upload it
          if (photo.file) {
            const uniqueFilename = `${uuidv4()}_${photo.file.name}`;
            const storageRef = ref(storage, `inventory_photos/${uniqueFilename}`);

            try {
              const uploadResult = await uploadBytes(storageRef, photo.file);
              const downloadURL = await getDownloadURL(uploadResult.ref);

              return {
                type: photo.type || '',
                description: photo.description || '',
                url: downloadURL
              };
            } catch (error) {
              console.error('Error uploading photo:', photo.file.name, error);
              return null;
            }
          }

          return null;
        })
      );

      // Filter out null values and empty photos
      const validPhotos = uploadedPhotoUrls.filter(
        (photo) => photo !== null && photo.url
      );

      const updatedInventoryFormData = {
        ...formData,
        photos: validPhotos,
        availabilityDate: formData.availabilityDate || new Date().toISOString().split('T')[0],
        lastUpdatedDate: formData.lastUpdatedDate || new Date().toISOString().split('T')[0]
      };

      console.log('Submitting form with data:', updatedInventoryFormData);
      
      if (typeof onSubmit === 'function') {
        await onSubmit(updatedInventoryFormData);
        handleClose();
      } else {
        console.error('onSubmit is not a function:', onSubmit);
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const handleCenterSelect = (event, newValue) => {
    console.log('Selected center:', newValue);
    if (newValue) {
      setFormData(prev => ({
        ...prev,
        centerName: newValue.centerName || '',
        centerId: newValue.id || '',
        city: newValue.city || '',
        locality: newValue.locality || '',
        micromarket: newValue.micromarket || '',
        address: newValue.address || '',
        googleMapsUrl: newValue.googleMapsUrl || '',
        centerDetails: newValue.centerDetails || ''
      }));
    } else {
      // Clear center-related fields if no center is selected
      setFormData(prev => ({
        ...prev,
        centerName: '',
        centerId: '',
        city: '',
        locality: '',
        micromarket: '',
        address: '',
        googleMapsUrl: '',
        centerDetails: ''
      }));
    }
  };

  const handleOperatorSelect = (event, newValue) => {
    console.log('Selected operator:', newValue);
    if (newValue) {
      setFormData(prev => ({
        ...prev,
        operatorName: newValue.operatorName || '',
        operatorId: newValue.id || ''
      }));
    } else {
      // Clear operator-related fields if no operator is selected
      setFormData(prev => ({
        ...prev,
        operatorName: '',
        operatorId: ''
      }));
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="inventory-modal-title"
      >
        <Box sx={modalStyle}>
          <Box sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E5E7EB',
            marginBottom: 2
          }}>
            <Typography variant="h5" component="h2">
              {editMode ? 'Edit Inventory' : 'New Inventory'}
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Inventory Details
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Operator <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                  </Typography>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={operators.sort((a, b) => 
                      a.operatorName.localeCompare(b.operatorName)
                    )}
                    getOptionLabel={(option) => option.operatorName || ''}
                    value={
                      operators.find(
                        (operator) => operator.id === formData.operatorId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleOperatorSelect(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        placeholder="-Select-"
                        error={!!errors.operatorName}
                        helperText={errors.operatorName}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Center <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                  </Typography>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={centers.sort((a, b) => 
                      a.centerName.localeCompare(b.centerName)
                    )}
                    getOptionLabel={(option) => option.centerName || ''}
                    value={
                      centers.find(
                        (center) => center.id === formData.centerId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleCenterSelect(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        placeholder="-Select-"
                        error={!!errors.centerName}
                        helperText={errors.centerName}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Inventory Name *"
                    value={formData.inventoryName}
                    onChange={(e) => {
                      setFormData({ ...formData, inventoryName: e.target.value });
                      if (errors.inventoryName) {
                        setErrors({ ...errors, inventoryName: '' });
                      }
                    }}
                    error={!!errors.inventoryName}
                    placeholder="Enter inventory name"
                  />
                  {errors.inventoryName && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.inventoryName}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Cabin Number"
                    value={formData.cabinNumber}
                    onChange={(e) => setFormData({ ...formData, cabinNumber: e.target.value })}
                    placeholder="Enter cabin number"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Size"
                    type="number"
                    value={formData.size}
                    onChange={(e) => {
                      setFormData({ ...formData, size: e.target.value });
                      if (errors.size) {
                        setErrors({ ...errors, size: '' });
                      }
                    }}
                    error={!!errors.size}
                    placeholder="Enter size"
                  />
                  {errors.size && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.size}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.inventoryType}>
                    <InputLabel>Inventory Type</InputLabel>
                    <Select
                      value={formData.inventoryType}
                      label="Inventory Type"
                      onChange={(e) => {
                        setFormData({ ...formData, inventoryType: e.target.value });
                        if (errors.inventoryType) {
                          setErrors({ ...errors, inventoryType: '' });
                        }
                      }}
                    >
                      <MenuItem value="Private Cabin">Private Cabin</MenuItem>
                      <MenuItem value="Desk">Desk</MenuItem>
                      <MenuItem value="Desk">Warm Shell</MenuItem>
                    </Select>
                    {errors.inventoryType && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.inventoryType}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Floor"
                    value={formData.floor}
                    onChange={(e) => setFormData({ ...formData, floor: e.target.value })}
                    placeholder="2nd Floor"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    type="number"
                    value={formData.quantity}
                    onChange={(e) => {
                      setFormData({ ...formData, quantity: e.target.value });
                      if (errors.quantity) {
                        setErrors({ ...errors, quantity: '' });
                      }
                    }}
                    error={!!errors.quantity}
                    placeholder="Enter quantity"
                  />
                  {errors.quantity && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.quantity}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Availability Date"
                    type="date"
                    value={formData.availabilityDate}
                    onChange={(e) => {
                      setFormData({ ...formData, availabilityDate: e.target.value });
                      if (errors.availabilityDate) {
                        setErrors({ ...errors, availabilityDate: '' });
                      }
                    }}
                    error={!!errors.availabilityDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.availabilityDate && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.availabilityDate}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Updated Date"
                    type="date"
                    value={formData.lastUpdatedDate}
                    onChange={(e) => {
                      setFormData({ ...formData, lastUpdatedDate: e.target.value });
                      if (errors.lastUpdatedDate) {
                        setErrors({ ...errors, lastUpdatedDate: '' });
                      }
                    }}
                    error={!!errors.lastUpdatedDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.lastUpdatedDate && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.lastUpdatedDate}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.active}
                        onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                        sx={{
                          color: theme?.palette.primary.main,
                          '&.Mui-checked': {
                            color: theme?.palette.primary.main,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color="text.secondary">
                        Active <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                      </Typography>
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Center Details
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    value={formData.city}
                    onChange={(e) => {
                      setFormData({ ...formData, city: e.target.value });
                      if (errors.city) {
                        setErrors({ ...errors, city: '' });
                      }
                    }}
                    error={!!errors.city}
                    placeholder="Enter city"
                  />
                  {errors.city && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.city}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Locality"
                    value={formData.locality}
                    onChange={(e) => {
                      setFormData({ ...formData, locality: e.target.value });
                      if (errors.locality) {
                        setErrors({ ...errors, locality: '' });
                      }
                    }}
                    error={!!errors.locality}
                    placeholder="Enter locality"
                  />
                  {errors.locality && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.locality}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    multiline
                    rows={3}
                    value={formData.address}
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Micromarket"
                    value={formData.micromarket}
                    disabled
                    // onChange={(e) => {
                    //   setFormData({ ...formData, micromarket: e.target.value });
                    //   if (errors.micromarket) {
                    //     setErrors({ ...errors, micromarket: '' });
                    //   }
                    // }}
                    // error={!!errors.micromarket}
                    // placeholder="Enter micromarket"
                  />
                  {errors.micromarket && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.micromarket}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Google Maps URL"
                    value={formData.googleMapsUrl}
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Inventory Photos
                  </Typography>
                  {photos.map((photo, index) => (
                    <Box 
                      key={index} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        mb: 2,
                        p: 2,
                        border: '1px solid #e0e0e0',
                        borderRadius: 1
                      }}
                    >
                      {/* Photo Preview */}
                      {(photo.url || (photo.file && URL.createObjectURL(photo.file))) && (
                        <Box
                          component="img"
                          src={photo.file ? URL.createObjectURL(photo.file) : photo.url}
                          alt={photo.description || 'Inventory photo'}
                          sx={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            borderRadius: 1
                          }}
                        />
                      )}
                      {!photo.url && !photo.file && (
                        <Box
                          sx={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px dashed #ccc',
                            borderRadius: 1,
                            bgcolor: '#f5f5f5'
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, index)}
                            style={{ display: 'none' }}
                            id={`photo-upload-${index}`}
                          />
                          <label htmlFor={`photo-upload-${index}`}>
                            <IconButton component="span" color="primary">
                              <AddIcon />
                            </IconButton>
                          </label>
                        </Box>
                      )}

                      {/* Photo Type and Description */}
                      <Box sx={{ flexGrow: 1, display: 'flex', gap: 2 }}>
                        <FormControl sx={{ width: '200px' }}>
                          <InputLabel>Photo Type</InputLabel>
                          <Select
                            value={photo.type || ''}
                            onChange={(e) => handlePhotoChange(index, 'type', e.target.value)}
                            label="Photo Type"
                            size="small"
                          >
                            {photoTypes.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          size="small"
                          label="Description"
                          value={photo.description || ''}
                          onChange={(e) => handlePhotoChange(index, 'description', e.target.value)}
                        />
                      </Box>

                      {/* Delete Button */}
                      <IconButton 
                        onClick={() => handleRemovePhoto(index)} 
                        color="error"
                        sx={{ flexShrink: 0 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddPhoto}
                    variant="outlined"
                    sx={{ mt: 1 }}
                  >
                    Add Photo
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                      variant="outlined"
                      onClick={handleReset}
                      sx={{
                        borderColor: theme?.palette.primary.main,
                        color: theme?.palette.primary.main,
                        '&:hover': {
                          borderColor: theme?.palette.primary.dark,
                          backgroundColor: 'transparent',
                        }
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        bgcolor: theme?.palette.primary.main,
                        '&:hover': {
                          bgcolor: theme?.palette.primary.dark
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        editMode ? 'Update ' : 'Add Inventory'
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      <CenterForm
        open={openCenterForm}
        onClose={() => setOpenCenterForm(false)}
        theme={theme}
        operators={operators}
        proposalId={initialData?.proposalId}
        proposedOptions={initialData?.proposedOptions}
        editMode={true}
        initialData={{
          ...formData,
          id: formData.centerId,
          centerName: formData.centerName
        }}
        onSubmit={async (newCenter) => {
          setOpenCenterForm(false);
          if (onSubmit) {
            await onSubmit({
              ...formData,
              virtualTours: newCenter.virtualTours,
              centerName: newCenter.centerName,
              centerId: newCenter.id
            });
          }
        }}
      />
    </>
  );
};

export default InventoryForm;
