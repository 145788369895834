import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindowF, DirectionsRenderer } from '@react-google-maps/api';
import { Box, Tabs, Tab } from '@mui/material';
import "../../../Styles/Styles.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/config';

const containerStyle = {
    width: '100%',
    height: '50vh',
    border: "2px solid #ddd"
};

// Custom map styles
const mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ADD8E6"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
];

const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    fullscreenControl:true,
    zoomControl: true,
};

const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(price)
      : "N/A";

const Recommandedlocation = ({ 
    proposal,
    selectedTab,
    onTabChange
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs',
        libraries: ['places', 'maps']
    });

    const [map, setMap] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [zoom, setZoom] = useState(13);
    const [center, setCenter] = useState({ lat: 13.0827, lng: 80.2707 });
    const [searchedPlace, setSearchedPlace] = useState(null);
    const [directions, setDirections] = useState(null);
    const [selectedDestination, setSelectedDestination] = useState(null);
    // Filter options based on selected tab
    const recommendedOptions = proposal?.proposedOptions?.filter(option => option.isRecommended) || [];
    const otherOptions = proposal?.proposedOptions?.filter(option => !option.isRecommended) || [];
    const currentOptions = selectedTab === 0 ? recommendedOptions : otherOptions;

    // Create DirectionsService instance
    const directionsService = isLoaded ? new window.google.maps.DirectionsService() : null;

    // Function to calculate and display directions
    const calculateDirections = useCallback((destination) => {
        if (!searchedPlace || !directionsService) return;
        // Clear previous directions
        setDirections(null);
        const request = {
            origin: searchedPlace.position,
            destination: { lat: Number(destination.lat), lng: Number(destination.lng) },
            travelMode: window.google.maps.TravelMode.DRIVING
        };

        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
                setSelectedDestination(destination);
            }
        });
    }, [searchedPlace, directionsService]);

    // Clear directions when search place changes
    useEffect(() => {
        setDirections(null);
        setSelectedDestination(null);
    }, [searchedPlace]);

    // Get locations for the current tab
    const locations = currentOptions
        .filter(option => option.location && option.location.lat && option.location.lng)
        .map((option) => {
            const loc = option.location;
            return {
                lat: Number(loc.lat),
                lng: Number(loc.lng),
                centerName: option.centerName,
                locality: option.locality,
                billableSeats: option.billableSeats,
                pricePerSeat: formatPrice(option.pricePerSeat),
                availableFrom: option.availableFrom
            };
        });
    


    useEffect(() => {
        if (isLoaded) {
            const input = document.getElementById('location-search');
            const options = {
                componentRestrictions: { country: 'in' },
                types: ['geocode', 'establishment'],
                fields: ['address_components', 'geometry', 'name', 'formatted_address']
            };
            const autocomplete = new window.google.maps.places.Autocomplete(input, options);
            
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const newLocation = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };
                    setCenter(newLocation);
                    setSearchedPlace({
                        position: newLocation,
                        name: place.name,
                        address: place.formatted_address
                    });
                    if (map) {
                        map.panTo(newLocation);
                        map.setZoom(15);
                    }
                }
            });
        }
    }, [isLoaded, map]);


    useEffect(() => {
      // if (!isLoaded) return null;
        if (locations.length > 0 && isLoaded && map) {
            // const newCenter = { 
            //     lat: Number(locations[0].lat), 
            //     lng: Number(locations[0].lng) 
            // };
            
            // // Only update if the center has actually changed
            // if (center.lat !== newCenter.lat || center.lng !== newCenter.lng) {
            //     setCenter(newCenter);
            //     if (map) {
            //         map.panTo(newCenter);
            //     }
            // }
           
              const bounds = new window.google.maps.LatLngBounds();
              locations.forEach((location) => {
                  bounds.extend(new window.google.maps.LatLng(Number(location.lat), Number(location.lng)));
              });
              map.fitBounds(bounds);
          
          if (searchedPlace) {
            bounds.extend(new window.google.maps.LatLng(searchedPlace.position.lat, searchedPlace.position.lng));
            map.fitBounds(bounds);
        }
      }
        
    }, [selectedTab, locations, map, center.lat, center.lng,searchedPlace]);

    const handleTabChange = async (event, newValue) => {
        onTabChange(newValue);
        setSelectedMarker(null);

        // Update Firestore
        try {
            if (proposal?.id) {
                const proposalRef = doc(db, 'proposals', proposal.id);
                await updateDoc(proposalRef, {
                    proposedOptions: proposal.proposedOptions
                });
            }
        } catch (error) {
            console.error('Error updating Firestore:', error);
        }
    };

    const onLoad = useCallback((map) => {
        if (locations.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            locations.forEach((location) => {
                bounds.extend(new window.google.maps.LatLng(Number(location.lat), Number(location.lng)));
            });
            map.fitBounds(bounds);
        }
        setMap(map);
    }, [locations]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleTouchStart = (event, rowId) => {
        if (map && locations[rowId]) {
            map.panTo({ 
                lat: Number(locations[rowId].lat), 
                lng: Number(locations[rowId].lng) 
            });
            setSelectedMarker(locations[rowId]);
        }
    };

    const handleMarkerClick = (location) => {
        setSelectedMarker(location);
        if (searchedPlace) {
            calculateDirections(location);
        }
    };

    return isLoaded ? (
        <div className="container mt-4">
            <h1 className="display-4 text-start mb-4">
            Proposed Options
            </h1>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab sx={{ fontWeight: 'bold' , fontSize: '1rem' }} label={`Recommended Options (${recommendedOptions.length})`} />
                    <Tab sx={{ fontWeight: 'bold' , fontSize: '1rem'}} label={`Other Options (${otherOptions.length})`} />
                </Tabs>
            </Box>

            <div style={{ marginBottom: '20px' }}>
                <input
                    id="location-search"
                    type="text"
                    placeholder="Find distance from your location"
                    style={{
                        width: '100%',
                        height: '40px',
                        padding: '0 12px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        fontSize: '14px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}
                />
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-12 mb-3">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={mapOptions}
                    >
                        {/* Render directions if available */}
                        {directions && (
                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    suppressMarkers: true,
                                    polylineOptions: {
                                        strokeColor: "#2196F3",
                                        strokeWeight: 4
                                    }
                                }}
                            />
                        )}

                        {/* Markers for locations */}
                        {locations.map((location, index) => (
                            <MarkerF
                                key={index}
                                position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                                onClick={() => handleMarkerClick(location)}
                                options={{ clickable: true }}
                                label={`${index + 1}`}
                            />
                        ))}

                        {/* Special marker for searched place */}
                        {searchedPlace && (
                            <MarkerF
                                position={searchedPlace.position}
                                icon={{
                                    url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                                    scaledSize: new window.google.maps.Size(40, 40)
                                }}
                            />
                        )}

                        {selectedMarker && (
                            <InfoWindowF
                                position={{ lat: Number(selectedMarker.lat), lng: Number(selectedMarker.lng) }}
                                onCloseClick={() => {
                                    setSelectedMarker(null);
                                    setDirections(null);
                                    setSelectedDestination(null);
                                }}
                            >
                                <div>
                                    <h6>{selectedMarker.centerName}</h6>
                                    <p>{selectedMarker.locality}</p>
                                    {selectedMarker.billableSeats && (
                                        <>
                                            <p>Available Seats: {selectedMarker.billableSeats}</p>
                                            <p>Price per Seat: {selectedMarker.pricePerSeat}</p>
                                            <p>Available From: {selectedMarker.availableFrom}</p>
                                        </>
                                    )}
                                    {directions && selectedDestination && selectedDestination.lat === selectedMarker.lat && (
                                        <div style={{ borderTop: '1px solid #ccc', marginTop: '8px', paddingTop: '8px' }}>
                                            <p><strong>Driving Distance:</strong> {directions.routes[0].legs[0].distance.text}</p>
                                            <p><strong>Duration:</strong> {directions.routes[0].legs[0].duration.text}</p>
                                        </div>
                                    )}
                                </div>
                            </InfoWindowF>
                        )}
                    </GoogleMap>
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="table-responsive">
                        <div className="table-container">
                            <table className="location-table table-bordered" style={{ maxHeight: "600px", overflowY: "scroll" }}>
                                <tbody>
                                    {currentOptions.map((item, index) => (
                                        <tr 
                                            key={index} 
                                            onTouchStart={(e) => handleTouchStart(e, index)}
                                            onClick={(e) => handleTouchStart(e, index)}
                                        >
                                            <td>{index + 1}</td>
                                            <td className='location-column p-3'>
                                                {item.centerName || "N/A"}
                                            </td>
                                            <td className='p-3'>{item.location.address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="text-center">Loading...</div>
    );
};

export default Recommandedlocation;
