import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Typography,
    Divider,
    CircularProgress,
    Popover,
    List,
    ListItem,
    ListItemText,
    Container,
    Tooltip,
    ButtonGroup,
    DialogActions,
    ImageList,
    ImageListItem,
    Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { searchInventory } from '../../../Services/Service';
import useMeta from '../../../hooks/useMeta';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';

const SearchContent = ({ onClose, onAddToProposal, isDialog }) => {
    const [formData, setFormData] = useState({
        city: 'Chennai',
        micromarket: 'Guindy',
        budgetFrom: '10000',
        budgetTo: '30000',
        seatsNeeded: '50',
        expectedStart: null
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchResultData, setSearchResultData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const { cities, getMicromarketsForCity } = useMeta();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => {
            if (name === 'city') {
                return {
                    ...prev,
                    [name]: value,
                    micromarket: ''
                };
            }
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({
            ...prev,
            expectedStart: date
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = async () => {
        setLoading(true);
        setError(null);

        const result = await searchInventory(formData);

        if (result.success) {
            setSearchResultData(result.data);
        } else {
            setError(result.error);
        }

        setLoading(false);
    };

    const handleClear = () => {
        setFormData({
            city: '',
            micromarket: '',
            budgetFrom: '',
            budgetTo: '',
            seatsNeeded: '',
            expectedStart: null
        });
        setSearchResultData([]);
        setError(null);
        setPage(0);
    };

    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleViewDetails = (row) => {
        setSelectedRow(row);
        setDetailsOpen(true);
        setAnchorEl(null); // Close the menu
    };

    const handleCloseDetails = () => {
        setDetailsOpen(false);
        setSelectedRow(null);
    };

    const handleAddToProposal = (isRecommended) => {
        if (onAddToProposal) {
            const centerWithRecommendedStatus = {
                ...selectedRow,
                isRecommended: isRecommended
            };

            onAddToProposal(centerWithRecommendedStatus);
        }
        handleMenuClose();
        if (onClose) onClose();
    };

    const handleDownloadCSV = () => {
        if (searchResultData.length === 0) return;

        const headers = [
            'Operator',
            'Center Name',
            'Inventory',
            'Billable Seats',
            'Availability Date',
            'Price/Seat',
            'Micromarket',
            'Locality'
        ];

        const data = searchResultData.map(row => [
            row.operatorName || '',
            row.centerName || '',
            row.inventory || '',
            row.billableSeats || '',
            row.availabilityDate || '',
            row.pricePerSeat || '',
            row.microMarket || '',
            row.locality || ''
        ]);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Search Results');
        
        XLSX.writeFile(workbook, 'search_results.csv');
    };

    const currentPageData = searchResultData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const searchForm = (
        <Grid container spacing={2}>
            {/* First Row */}
            <Grid item xs={12} md={6}>
                <FormControl fullWidth required size="small">
                    <InputLabel>City</InputLabel>
                    <Select
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        label="City"
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {cities.map((city) => (
                            <MenuItem key={city} value={city}>
                                {city}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth required size="small">
                    <InputLabel>Micromarket</InputLabel>
                    <Select
                        name="micromarket"
                        value={formData.micromarket}
                        onChange={handleChange}
                        label="Micromarket"
                        disabled={!formData.city}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {formData.city && getMicromarketsForCity(formData.city)?.map((market) => (
                            <MenuItem key={market} value={market}>
                                {market}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Second Row */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    size="small"
                    label="Budget From"
                    name="budgetFrom"
                    value={formData.budgetFrom}
                    onChange={handleChange}
                    type="number"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    size="small"
                    label="Budget To"
                    name="budgetTo"
                    value={formData.budgetTo}
                    onChange={handleChange}
                    type="number"
                />
            </Grid>

            {/* Third Row */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    size="small"
                    label="Seats Needed"
                    name="seatsNeeded"
                    value={formData.seatsNeeded}
                    onChange={handleChange}
                    type="number"
                />
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Expected Start"
                        value={formData.expectedStart}
                        onChange={handleDateChange}
                        format="DD-MMM-YYYY"
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                required: true,
                                size: "small"
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid> */}

            {/* Buttons */}
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSearch}
                        startIcon={<SearchIcon />}
                        sx={{ minWidth: 100 }}
                    >
                        Search
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );

    const searchResultsSection = (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">
                    Search Results
                </Typography>
                {searchResultData.length > 0 && (
                    <ButtonGroup variant="outlined" size="small">
                        <Tooltip title="Download as CSV">
                            <Button
                                startIcon={<PictureAsPdfIcon />}
                                onClick={handleDownloadCSV}
                            >
                                CSV
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                )}
            </Box>
            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}
            <TableContainer sx={{ mt: 2 }}>
                <Table size="small">
                    <TableHead>
                    <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
                            <TableCell>Operator</TableCell>
                            <TableCell>Center Name</TableCell>
                            <TableCell>Inventory</TableCell>
                            <TableCell>Billable Seats</TableCell>
                            <TableCell>Availability Date</TableCell>
                            <TableCell>Price Per Seat</TableCell>
                            <TableCell>Micromarket</TableCell>
                            <TableCell>Locality</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={9} align="center" sx={{ py: 3 }}>
                                    <CircularProgress size={24} />
                                </TableCell>
                            </TableRow>
                        ) : searchResultData.length > 0 ? (
                            currentPageData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.operatorName}</TableCell>
                                    <TableCell>{row.centerName}</TableCell>
                                    <TableCell>{row.inventory}</TableCell>
                                    <TableCell>{row.billableSeats}</TableCell>
                                    <TableCell>{row.availabilityDate}</TableCell>
                                    <TableCell>{row.pricePerSeat}</TableCell>
                                    <TableCell>{row.microMarket}</TableCell>
                                    <TableCell>{row.locality}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={(event) => handleMenuClick(event, row)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    No results found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {searchResultData.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <TablePagination
                            component="div"
                            count={searchResultData.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            labelRowsPerPage="Rows per page:"
                            labelDisplayedRows={({ from, to, count }) => `${from}–${to} of ${count}`}
                            sx={{
                                '.MuiTablePagination-displayedRows': {
                                    margin: '0 20px'
                                },
                                '.MuiTablePagination-selectLabel': {
                                    marginRight: '10px'
                                }
                            }}
                        />
                    </Box>
                )}
            </TableContainer>
        </Box>
    );

    const content = (
        <>
            {searchForm}
            {searchResultsSection}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List 
                sx={{ width: 250 }}>
                    <ListItem button onClick={() => handleViewDetails(selectedRow)}>
                        <ListItemText primary="View Details" />
                    </ListItem>
                    {isDialog && (
                        <>
                            <ListItem button onClick={() => handleAddToProposal(true)}>
                                <ListItemText primary="Add as Recommended Option" />
                            </ListItem>
                            <ListItem button onClick={() => handleAddToProposal(false)}>
                                <ListItemText primary="Add as Other Option" />
                            </ListItem>
                        </>
                    )}
                </List>
            </Popover>
            {/* Center Details Dialog */}
            <Modal
                open={detailsOpen}
                onClose={handleCloseDetails}
                aria-labelledby="center-modal-title"
                aria-describedby="center-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '800px',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    border: '1px solid #E5E7EB',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {/* Header */}
                    <Box sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #E5E7EB',
                        bgcolor: 'background.paper',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}>
                        <Typography variant="h6" component="h2">
                            View Details
                        </Typography>
                        <IconButton
                            onClick={handleCloseDetails}
                            size="small"
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'text.primary',
                                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Scrollable Content */}
                    <Box sx={{
                        overflowY: 'auto',
                        flex: 1,
                        p: 3,
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: '#555',
                            },
                        },
                    }}>
                        {selectedRow && (
                            <Box sx={{ p: 3 }}>
                                {/* Photos Grid */}
                                {selectedRow.photos && selectedRow.photos.length > 0 && (
                                    <Grid item xs={12} sx={{ mb: 3 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                                            Photos
                                        </Typography>
                                        <Grid container spacing={2}>
                                            {selectedRow.photos.map((photo, index) => (
                                                <Grid item xs={3} key={index} sx={{ position: 'relative' }}>
                                                    <Box
                                                        component="img"
                                                        src={photo.url}
                                                        alt={photo.name || `Photo ${index + 1}`}
                                                        sx={{
                                                            width: '100%',
                                                            height: 120,
                                                            objectFit: 'cover',
                                                            borderRadius: 1,
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid container spacing={3}>
                                    {/* Basic Information */}
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                                            Basic Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Name</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedRow.centerName || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Building Type</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedRow.buildingType || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Building Grade</Typography>
                                                <Typography variant="body1">{selectedRow.buildingGrade || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Active</Typography>
                                                <Typography variant="body1">{selectedRow.active ? 'Yes' : 'No'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Location Information */}
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                                            Location Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Address</Typography>
                                                <Typography variant="body1" sx={{
                                                    p: 1.5,
                                                    bgcolor: 'grey.50',
                                                    borderRadius: 1
                                                }}>{selectedRow.address || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>City</Typography>
                                                <Typography variant="body1">{selectedRow.city || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Locality</Typography>
                                                <Typography variant="body1">{selectedRow.locality || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Google Maps Location</Typography>
                                                <Typography variant="body1" sx={{
                                                    p: 1.5,
                                                    bgcolor: 'grey.50',
                                                    borderRadius: 1,
                                                    wordBreak: 'break-all'
                                                }}>{selectedRow.googleMapsLocation || 'N/A'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Contact Information */}
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                                            Contact Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Manager Name</Typography>
                                                <Typography variant="body1">{selectedRow.centerManagerName || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Manager Phone</Typography>
                                                <Typography variant="body1">{selectedRow.centerManagerPhone || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Manager Email</Typography>
                                                <Typography variant="body1">{selectedRow.centerManagerEmail || 'N/A'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Additional Information */}
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                                            Additional Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operational Hours</Typography>
                                                <Typography variant="body1">{selectedRow.operationalHours || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operational From</Typography>
                                                <Typography variant="body1">{selectedRow.operationalFrom || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>List Price</Typography>
                                                <Typography variant="body1">₹{selectedRow.pricePerSeat || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Cafeteria Capacity</Typography>
                                                <Typography variant="body1">{selectedRow.cafeteriaCapacity || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Car Parking Cost</Typography>
                                                <Typography variant="body1">{selectedRow.carParkingCost || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Bike Parking Cost</Typography>
                                                <Typography variant="body1">{selectedRow.bikeParkingCost || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Description</Typography>
                                                <Typography variant="body1" sx={{
                                                    p: 1.5,
                                                    bgcolor: 'grey.50',
                                                    borderRadius: 1,
                                                    minHeight: '60px'
                                                }}>{selectedRow.description || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Amenities</Typography>
                                                <Typography variant="body1" sx={{
                                                    p: 1.5,
                                                    bgcolor: 'grey.50',
                                                    borderRadius: 1
                                                }}>
                                                    {selectedRow.amenities && selectedRow.amenities.length > 0
                                                        ? selectedRow.amenities
                                                            .map(amenity => amenity.trim())
                                                            .filter(Boolean)
                                                            .join(', ')
                                                        : 'N/A'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );

    return isDialog ? content : (
        <Paper sx={{ p: 3 }}>
            {content}
        </Paper>
    );
};

const SearchCenterDialog = ({ open = false, onClose, onAddToProposal, isDialog = true }) => {
    // If it's not a dialog, render the content directly
    if (!isDialog) {
        return <SearchContent isDialog={false} />;
    }

    // If it's a dialog, wrap the content in a Dialog component
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    Search Options
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <SearchContent onClose={onClose} onAddToProposal={onAddToProposal} isDialog={true} />
            </DialogContent>
        </Dialog>
    );
};

export default SearchCenterDialog;
